import { useTranslation } from 'react-i18next';

import UiMenu from 'components/ui/menu/Menu';

import '../categoryMenu.css';

function ItemMenuView({
    rootLink,
    className,
    category,
    activeSubItemId,
    onBackPressed,
    onOptionLinkClicked,
}) {
    const [t] = useTranslation('global');
    return (
        <UiMenu className={className}>
            <UiMenu.BackButton onClick={onBackPressed}>
                {t('categoriesMenu.backButtonTitle')}
            </UiMenu.BackButton>
            <UiMenu.Title>{category.label}</UiMenu.Title>
            {category.subItems && (
                <UiMenu.Options>
                    {category.subItems.map((subItem) => (
                        <UiMenu.Option
                            key={`subitemCategoryMenu-${subItem.id}`}
                            link={`${rootLink}${subItem.link}`}
                            className={`categoryMenu__subOption ${
                                activeSubItemId === subItem.id ? 'active' : 'inactive'
                            }`}
                            onOptionLinkClicked={onOptionLinkClicked}
                        >
                            {subItem.label}
                        </UiMenu.Option>
                    ))}
                </UiMenu.Options>
            )}
        </UiMenu>
    );
}

export default ItemMenuView;
