/* eslint-disable no-underscore-dangle */
import { useCategory } from 'infrastructure/services/CategoriesService';


export default function useRetrieveCategories() {

    const categoryService = useCategory();

    const mapCategory = (category) => ({
        id: category._id,
        title: category.name,
        slug: `${category.slug}/${category._id}`,
        image: category.images.length ? {
            url: category.images[0].fileUrl,
            description: category.images[0].seoName,
        } : undefined,
    });

    async function retrieveCategories(filters) {
        try {
            const params = {
                menu: true,
                root: true,
                includeChilds: false,
                limit: filters?.limit
            }
            const categories = await categoryService.getCategories(params);
            return categories.map(category => mapCategory(category));

        } catch (e) {
            return [];
        }

    }


    return { retrieveCategories };
}