export const optionsMenu = {
    optFree: [
        {
            label: 'iniciar sesión',
            key: 'iniciar-sesion'
        },
        {
            label: 'panel de control',
            key: 'dashboard'
        }
    ],
    optUser: [
        {
            label: 'mi cuenta',
            acces: ['CLIENT','DESIGNER_ROLE'],
            key: 'profile'
        },
        {
            label: 'cerrar sesión',
            acces: ['CLIENT','DESIGNER_ROLE'],
            key: 'cerrar-sesion'
        }
    ],
    optDashboard: [
        {
            label: 'inici',
            acces: ['SUPERADMIN','ADMIN_ROLE'],
            section: 'dashboard',
            key: 'home'
        },
        {
            label: 'gestión datos tienda',
            acces: ['SUPERADMIN','ADMIN_ROLE'],
            section: 'dashboard',
            key: 'manage-shop-config'
        },
        {// disabled delete files
            label: 'gestión medios',
            acces: ['SUPERADMIN'],
            section: 'dashboard',
            key: 'manage-media'
        },
        {
            label: 'modelos 3d',
            acces: ['SUPERADMIN','ADMIN_ROLE'],
            section: 'dashboard',
            key: 'manage-3dmodels'
        },
        {
            label: 'imagenes',
            acces: ['SUPERADMIN','ADMIN_ROLE'],
            section: 'dashboard',
            key: 'manage-pictures'
        },
        {
            label: 'gestión moldes',
            acces: ['SUPERADMIN','ADMIN_ROLE'],
            section: 'dashboard',
            key: 'manage-molds'
        },
        {
            label: 'categorías',
            acces: ['SUPERADMIN','ADMIN_ROLE'],
            section: 'dashboard',
            key: 'manage-categories'
        },
        {
            label: 'alta productos',
            acces: ['SUPERADMIN','ADMIN_ROLE'],
            section: 'dashboard',
            key: 'new-products'
        },
        {
            label: 'Colores Acabados',
            acces: ['SUPERADMIN'],
            section: 'dashboard',
            key: 'manage-finish-colors'
        },

        {
            label: 'productes relaccionats',
            acces: ['SUPERADMIN'],
            section: 'dashboard',
            key: 'products-related'
        },
        {
            label: 'categories',
            acces: ['SUPERADMIN'],
            section: 'dashboard',
            key: 'categories'
        },
        {
            label: 'productes',
            acces: ['SUPERADMIN'],
            section: 'dashboard',
            key: 'products'
        },
        {
            label: 'gestió productes',
            acces: ['SUPERADMIN'],
            section: 'dashboard',
            key: 'gest-products'
        },
        {
            label: 'usuaris',
            acces: ['SUPERADMIN'],
            section: 'dashboard',
            key: 'users'
        },
        {
            label: 'acabats',
            acces: ['SUPERADMIN'],
            section: 'dashboard',
            key: 'finishes'
        },
        {
            label: 'materials',
            acces: ['SUPERADMIN'],
            section: 'dashboard',
            key: 'materials'
        },
        {
            label: 'comandes',
            acces: ['SUPERADMIN'],
            section: 'dashboard',
            key: 'orders'
        },
        {
            label: 'estat web',
            acces: ['SUPERADMIN'],
            section: 'dashboard',
            key: 'status-web',
        },
        {
            label: 'cerrar sesión',
            acces: ['ADMIN_ROLE','SUPERADMIN'],
            section: 'dashboard',
            key: 'cerrar-sesion'
        }
    ],
    order:{
        0:'home',
        1:'custom_service',
        2:'contact',
        3:'services',
        4:'shop',
        5:'profile',
        6:'signin',
        7:'about_me',
        8:'signup',
        9:'signout',
        10:'dashboard'
    },
    status:{
        home:false,
        custom_service:false,
        contact:false,
        services:false,
        shop:true,
        about_me:false,
        signup:true,
        signin:false,
        profile:false,
        signout:false,
        dashboard:false
    },
    navbar:{
        home: 'inici',
        custom_service: 'personalitza',
        contact: 'contacte',
        services: 'serveis',
        shop: 'botiga',
        order: 'comanda',
        about_me: 'qui som ?',
        signup: 'registre',
        signin: 'inicia sessió',
        term_con: 'termes i condicions',
        profile: 'usuari',
        signout: 'tanca sessió',
        dashboard: 'panell de control',
        privacy: 'Política de privacidad',
        thanks_order: 'Gracies per la seva compra !!'
    },
    navlinks:{
        home: '/',
        custom_service: '/personaliza',
        contact: '/contacto',
        services:'/servicios',
        shop: '/tienda',
        article: 'detalle-articulo',
        productExtends: 'articulo',
        about_me: '/quienes-somos',
        signup: '/registro',
        validation_account: '/validacion-registro',
        signin: '/iniciar-sesion',
        signInAdmin: '/admin-login',
        signout: '/cierra-sesion',
        recoveryPassword: '/recuperar-contraseña',
        term_con: '/terminos-condiciones',
        thanks_order: '/gracias-por-su-compra',
        privacy: 'politica-privacidad',
        order: '/pedido',
        profile: '/cuenta-usuario',
        cookies: '/politica-cookies',
        dashboard: '/panel-control'
    },
    navComp:{
        home: 'Home',
        custom_service: 'Customize',
        contact: 'Contac',
        services:'Services',
        shop: 'Shop',
        productExtends: 'ExtendsArticle',
        about_me: 'WhoaMe',
        signup: 'SignUp',
        validation_account: 'SignUpValidator',
        signin: 'SignIn',
        signout: 'SignOut',
        recoveryPassword: 'RecoveryPassword',
        term_con: 'TermsAndConditions',
        thanks_order: 'ThanksOrder',
        privacy: 'Privacy',
        order: 'Order',
        profile: 'Profile',
        cookies: 'CookiesPage',
        dashboard: 'Dashboard'
    }
}