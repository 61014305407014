import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import ProductList from 'components/product/list/List';

import './similarInterests.css';

function SimilarInterests({ products }) {
    const [t] = useTranslation('global');

    return (
        <div className='productSimilarInterests__container'>
            <Typography component='h3' className='productSimilarInterests__title' variant='h2'>
                {t('productDetail.similarInterestsTitle')}
            </Typography>
            <ProductList products={products} />
        </div>
    );
}

export default SimilarInterests;
