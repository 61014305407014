import { Link, Typography } from '@material-ui/core';
import useRetrieveProducts from 'application/useCases/retrieveProducts';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ProductList from 'components/product/list/List';

import './popularProducts.css';

function PopularProducts() {
    const [t] = useTranslation('global');
    const [wishList, setWishList] = useState([4, 2]);
    const [popularProducts, setPopularProducts] = useState([]);

    const { retrieveProducts } = useRetrieveProducts();

    const addInWishList = (id) => {
        setWishList((list) => [...list, id]);
    };
    const removeFromWishList = (id) => {
        setWishList((list) => list.filter((item) => item !== id));
    };

    useEffect(() => {
        retrieveProducts({ limit: 4 }).then(({ items }) => setPopularProducts(items));
    }, []);

    return (
        <section id='homeProducts'>
            <div className='homeProducts__container standard-container'>
                <Typography variant='h2' component='h2' className='homeProducts__title'>
                    {t('popularProducts.title')}
                </Typography>
                <ProductList
                    className='homeProducts__products'
                    products={popularProducts}
                    wishList={{
                        items: wishList,
                        isInWishList: (id) => wishList.includes(id),
                        addInWishList,
                        removeFromWishList,
                    }}
                />
                <Link className='homeProducts__more' color='secondary' href={t('navLinks.shop')}>
                    {t('popularProducts.showMore')}
                </Link>
            </div>
        </section>
    );
}

export default PopularProducts;
