import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import { useRef } from 'react';

import './quantityField.css';

const KEY_UP = 'ArrowUp';
const KEY_DOWN = 'ArrowDown';

function QuantityField({
    id,
    label,
    value,
    onChange,
    onBlur,
    error,
    disabled,
    required,
    minValue,
    maxValue,
    fullWidth,
}) {
    const inputRef = useRef(null);
    const handleMouseDown = (event) => {
        event.preventDefault();
    };

    const handleDecrement = () => {
        const newValue = parseInt(value, 10) - 1;
        if (minValue === undefined || newValue <= value) {
            onChange(newValue);
        }
    };

    const handleIncrement = () => {
        const newValue = parseInt(value, 10) + 1;
        if (maxValue === undefined || maxValue >= newValue) {
            onChange(newValue);
        }
    };

    const handleKeyDown = (e) => {
        const typed = e.key;

        if (typed !== KEY_DOWN && typed !== KEY_UP) {
            e.preventDefault();
        }
        const number = +typed;
        if (Number.isNaN(number)) {
            return;
        }

        if (+(e.target.value + number) <= maxValue) {
            const input = inputRef.current.lastChild.children[1];
            input.value += number;
        } else {
            console.log(`Number too big! Max is ${maxValue}`);
        }
    };

    return (
        <TextField
            id={id}
            label={label}
            variant='outlined'
            type='number'
            value={value}
            onChange={(event) => onChange(event.target.value)}
            onBlur={onBlur}
            onKeyDown={handleKeyDown}
            disabled={disabled}
            min={minValue}
            max={maxValue}
            fullWidth={fullWidth}
            required={required}
            error={error}
            ref={inputRef}
            InputProps={{
                startAdornment: (
                    <InputAdornment
                        position='start'
                        style={{ marginRight: 0, marginLeft: 0, padding: '0px' }}
                    >
                        <IconButton
                            aria-label='decrease quantity'
                            onClick={handleDecrement}
                            onMouseDown={handleMouseDown}
                            disabled={minValue !== undefined && value <= minValue}
                            style={{ padding: '12px 5px 12px 12px' }}
                        >
                            <Remove style={{ width: '0.65em' }} />
                        </IconButton>
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment
                        position='end'
                        style={{ marginRight: 0, marginLeft: 0, padding: '0px' }}
                    >
                        <IconButton
                            aria-label='increase quantity'
                            onClick={handleIncrement}
                            onMouseDown={handleMouseDown}
                            disabled={maxValue !== undefined && value >= maxValue}
                            style={{ padding: '12px 12px 12px 5px' }}
                        >
                            <Add style={{ width: '0.65em' }} />
                        </IconButton>
                    </InputAdornment>
                ),
                style: { padding: 0 },
                inputProps: {
                    max: maxValue,
                    min: minValue,
                    style: { textAlign: 'center', padding: '14px 0' },
                },
            }}
        />
    );
}

export default QuantityField;
