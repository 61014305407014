import { createContext, useContext, useEffect, useState } from "react";

const NotifierContext = createContext({});
export const useNotifier = () => useContext(NotifierContext);

export function NotificationsProvider({ children }) {

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");

    const notify = (msg = 'Something went wrong...') => {
        setMessage(msg)
        setOpen(true);
    }

    const notifyError = (msg = 'Something went wrong...') => {
        setMessage(msg)
        setOpen(true);
        setSeverity('error')
    }


    useEffect(() => {
        if (open) {
            setTimeout(() => {
                setOpen(false);
            }, 3000);
        }
    }, [open]);



    return (
        // eslint-disable-next-line react/jsx-filename-extension, react/jsx-no-constructed-context-values
        <NotifierContext.Provider value={{
            message,
            open,
            notify,
            notifyError,
            severity

        }}> {children} </NotifierContext.Provider>
    );
}

