import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import './description.css';

function Description({ content }) {
    const [t] = useTranslation('global');

    return (
        <div className='productDescription'>
            <Typography component='h6' className='productDescription__title' variant='body2'>
                {t('productDetail.titleDescription')}
            </Typography>
            <Typography component='p' className='productDescription__text' variant='body1'>
                {content}
            </Typography>
        </div>
    );
}

export default Description;
