const infoClient = {
    name: 'dreamdo',
    respName: 'DREAMDO',  
    fiscalIdentity: 'B61982633',
    location: 'Arenys de Munt',
    cp: '08350',
    province: 'barcelona',
    mail_privacy: 'privacy@dreamdo.cat',
    mail_contact_home: 'regidormiquel@gmail.com',
    mail_register: 'no-reply@dreamdo.cat',
    mail_info: 'clients@dreamdo.cat',
    mails_order: ['dreamdo@gmail.com','regidormiquel@gmail.com'],
    domain: 'dreamdo.es',
    nameSender: 'Dreamdo',
    tlf1: '93 793 82 68',
    whatsAppLink: 'https://wa.me/',
    address: 'Rambla  Riera i Penya, nº 39',
    timeOpenSaturday: '09:30 - 13:30',
    timeOpenWeek: '09:00 - 13:00 / 17:00 - 20:30',
    shipmentCost: 7
  }

  export default infoClient;