/* eslint-disable react/jsx-no-useless-fragment */
import AppContext from 'domain/context/AppContext';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

function ProtectedClickEvent({ children }) {
    const { user } = useContext(AppContext);
    const getFirstChild = () => React.Children.toArray(children)[0];
    const location = useLocation();
    const navigate = useNavigate();
    const [t] = useTranslation('global');

    useEffect(() => {
        // if this component has a pending click with a child
        const pendingClickTargetId = location.state?.pendingClickTarget;
        if (pendingClickTargetId && getFirstChild().props.id === pendingClickTargetId) {
            const pendingClickTargetElement = document.getElementById(
                location.state.pendingClickTarget,
            );
            pendingClickTargetElement?.click();
        }
    }, []);

    const findSection = (element) => {
        if (element.nodeName === 'BODY') return null;
        if (element.parentElement.nodeName === 'SECTION') {
            return element.parentElement.id;
        }
        return findSection(element.parentElement);
    };
    const handleOnClick = (e, childOnClick) => {
        if (!user) {
            const section = findSection(e.target);
            navigate(t('navLinks.signIn'), {
                replace: true,
                from: {
                    ...location,
                    state: { pendingClickTarget: e.target.id, section },
                    replace: true,
                },
            });
        } else if (childOnClick) {
            childOnClick();
        }
    };

    if (children.length > 1) return <>{children}</>;

    const child = React.Children.toArray(children)[0];

    if (!React.isValidElement(child) || !child.props.id) return <>{children}</>;
    return (
        <>{React.cloneElement(child, { onClick: (e) => handleOnClick(e, child.props.onClick) })} </>
    );
}

export default ProtectedClickEvent;
