import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import MuiSelect from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useRef, useState } from 'react';

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function Select({ id, label, options, value, onChange, error, name }) {
    const classes = useStyles();

    const inputLabel = useRef(null);
    const [labelWidth, setLabelWidth] = useState(0);
    useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    return (
        <FormControl variant='outlined' className={classes.formControl} error={error}>
            <InputLabel ref={inputLabel} id={`${id}-label`}>
                {label}
            </InputLabel>
            <MuiSelect
                labelId={`${id}label`}
                id={id}
                name={name}
                value={value}
                onChange={onChange}
                labelWidth={labelWidth}
            >
                {options.map((item) => (
                    <MenuItem value={item.value} key={`selectItem-${item.value}`}>
                        {item.label}
                    </MenuItem>
                ))}
            </MuiSelect>
        </FormControl>
    );
}
