import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'

import './howItWorks.css'
import useHashScroll from 'hooks/useHashScroll'

function HowItWorks() {
  const [t] = useTranslation('global')
  const ref = useHashScroll(t('navSections.howItWorks'))

  return (
    <section ref={ref} id={t('navSections.howItWorks')} className='howItWorks'>
      <div className='standard-container howItWorks__container'>
        <div className='howItWorks__content'>
          <div className='howItWorks__introduction'>
            <Typography
              variant='h1'
              component='h2'
              className='howItWorks__title'
            >
              {t('howItWorks.title')}
            </Typography>
            <Typography component='p' variant='body1' color='secondary'>
              {t('howItWorks.description')}
            </Typography>
          </div>
          <List className='howItWorks__list'>
            <ListItem className='howItWorks__listItem'>
              <ListItemIcon>
                <img src='./images/howWorksIcon.png' alt='' />
              </ListItemIcon>
              <ListItemText
                primary={t('howItWorks.card1.title')}
                secondary={t('howItWorks.card1.text')}
              />
            </ListItem>
            <ListItem className='howItWorks__listItem'>
              <ListItemIcon>
                <img src='./images/howWorksIcon.png' alt='' />
              </ListItemIcon>
              <ListItemText
                primary={t('howItWorks.card2.title')}
                secondary={t('howItWorks.card2.text')}
              />
            </ListItem>
            <ListItem className='howItWorks__listItem'>
              <ListItemIcon>
                <img src='./images/howWorksIcon.png' alt='' />
              </ListItemIcon>
              <ListItemText
                primary={t('howItWorks.card3.title')}
                secondary={t('howItWorks.card3.text')}
              />
            </ListItem>
          </List>
        </div>
      </div>
    </section>
  )
}

export default HowItWorks
