/* eslint-disable react/jsx-props-no-spreading */
import Tab from '@material-ui/core/Tab';
import MuiTabs from '@material-ui/core/Tabs';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}));

function Tabs({ tabs, currentSelectedTab, className, rootLink }) {
    const classes = useStyles();
    const [value, setValue] = useState(currentSelectedTab);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {
        setValue(currentSelectedTab);
    }, [currentSelectedTab]);

    function a11yProps(index) {
        return {
            id: `nav-tab-${index}`,
            'aria-controls': `nav-tabpanel-${index}`,
        };
    }

    return (
        <div className={classes.root}>
            <MuiTabs
                value={value}
                onChange={handleChange}
                aria-label='nav tabs example'
                scrollButtons
                className={className}
            >
                {tabs.map((item, index) => (
                    <Tab
                        key={item.label}
                        label={item.label}
                        component={Link}
                        to={`${rootLink}${item.link}`}
                        {...a11yProps(index)}
                    />
                ))}
            </MuiTabs>
        </div>
    );
}

export default Tabs;
