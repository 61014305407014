// centralized request methods

const server = process.env.REACT_APP_API_SERVER
const get = async (url, body) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            "x-lang": "es" //TODO fix this
        }
    }

    const urlWithParams = body ? `${server}/api/${url}?${body.toString()}` : `${server}/api/${url}`
    const response = await fetch(urlWithParams, requestOptions)
    if (response.ok) {
        return await response.json();
    }
    throw Error(response.status);
}


const post = async (url, data, headers) => {

    let postHeaders = new Headers({
        "Content-Type": "application/json",
        "x-lang": "es"
    });
    if (headers) {
        Object.entries(headers).forEach(([key, value]) => postHeaders.append(key, value))
    }
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(data),
        redirect: 'follow',
        headers: postHeaders
    }
    const response = await fetch(`${server}/api/${url}`, requestOptions)
    if (response.ok) {
        return await response.json();
    }
    console.error("Reason:", response);
    throw Error(response.status);
}

const del = async (url, requestOptions) => {
    const response = await fetch(`${server}/api/${url}`, requestOptions)
    return response;
}

const put = async (url, requestOptions) => {
    const response = await fetch(`${server}/api/${url}`, requestOptions)
    return response;
}

export const http = {
    get,
    post,
    put,
    del
}