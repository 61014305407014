import { Link, Menu } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles({
    popOverRoot: {
        pointerEvents: 'none',
    },
});

function DropdownMenu({
    anchorEl,
    open,
    items,
    handleClose,
    handleCloseHover,
    handleHover,
    rootLink,
}) {
    const styles = useStyles();

    return (
        <Menu
            anchorEl={anchorEl}
            id='desktop-menu-dropdrown'
            open={open}
            onClose={handleClose}
            MenuListProps={{
                onMouseEnter: handleHover,
                onMouseLeave: handleCloseHover,
                style: { pointerEvents: 'auto' },
            }}
            getContentAnchorEl={null}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            PopoverClasses={{
                root: styles.popOverRoot,
            }}
        >
            <ul className='desktop-menu__dropdown-list'>
                {items.map((item) => (
                    <li key={`itemDropdown-${item.id}`} className='desktop-menu__dropdown-item'>
                        <Link
                            className='desktop-menu__dropdown-link'
                            href={`${rootLink}${item.link}`}
                        >
                            {item.label}
                        </Link>
                    </li>
                ))}
            </ul>
        </Menu>
    );
}

export default DropdownMenu;
