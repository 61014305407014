import { Link, Typography } from '@material-ui/core';
import useRetrieveCategories from 'application/useCases/retrieveCategories';
import useViewport from 'hooks/useViewport';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import CategoryCarousel from 'components/category/carousel/Carousel';
import CategoryList from 'components/category/list/List';

import './popularCategories.css';

function PopularCategories() {
    const { isDesktop } = useViewport();
    const [t] = useTranslation('global');
    const navigate = useNavigate();
    const [popularCategories, setPopularCategories] = useState([]);

    const { retrieveCategories } = useRetrieveCategories();

    useEffect(() => {
        retrieveCategories({ limit: 4 }).then((categories) => {
            setPopularCategories(categories);
            console.log('first');
        });
    }, []);

    const handleClickCard = (slug) => {
        navigate(`${t('navLinks.shop')}/${slug}`);
    };

    const buildCategories = (categories) => {
        if (!categories?.length) return null;
        return isDesktop() ? (
            <CategoryCarousel handleClickCard={handleClickCard} categories={categories} />
        ) : (
            <CategoryList handleClickCard={handleClickCard} categories={categories} />
        );
    };

    return (
        <section id='homeCategories'>
            <div className='homeCategories__container standard-container'>
                <Typography variant='h2' component='h2'>
                    {t('popularCategories.title')}
                </Typography>
                <div className='homeCategories__categories'>
                    {buildCategories(popularCategories)}
                </div>
                <Link
                    className='homeCategories__more'
                    color='secondary'
                    href={t('navLinks.categories')}
                >
                    {t('popularCategories.showMore')}
                </Link>
            </div>
        </section>
    );
}

export default PopularCategories;
