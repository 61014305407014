import { useTranslation } from 'react-i18next';

import ModalLink from 'components/ui/modalLink/ModalLink';
import UserModal from 'components/ui/userModal/UserModal';

import RegisterForm from '../form/Form';

const Modal = () => {
    const [t] = useTranslation('global');

    return (
        <UserModal title={t('register.title')}>
            <RegisterForm />
            <div className='userModal__callToActionLink'>
                {t('userModal.withAnAccountText')}
                <ModalLink to={t('navLinks.signIn')}>{t('userModal.loginLink')}</ModalLink>
            </div>
        </UserModal>
    );
};

export default Modal;
