import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ItemMenuView from 'components/category/menu/item/ItemMenuView';
import MainMenuView from 'components/category/menu/main/MainMenuView';
import Tabs from 'components/ui/tabs/Tabs';

// import './filter.css';

function Filter({ categories, isDesktop, criteria }) {
    const [t] = useTranslation('global');
    const SHOP_LINK = t('navLinks.shop');
    const navigate = useNavigate();
    const goBack = () => {
        navigate(SHOP_LINK);
    };

    const buildCategoriesMenu = (categoriesCriteria) => {
        if (categoriesCriteria.category) {
            const category = categories.find((item) => item.id === categoriesCriteria.category.id);
            return (
                <ItemMenuView
                    category={category}
                    activeSubItemId={categoriesCriteria.subCategory?.id}
                    onBackPressed={goBack}
                    className='categoryMenu'
                    rootLink={t('navLinks.shop')}
                />
            );
        }
        return <MainMenuView categories={categories} rootLink={t('navLinks.shop')} />;
    };
    const buildCategoriesTabs = (categoriesCriteria) => {
        let tabs = categories;
        let currentSelectedTab;
        if (categoriesCriteria.category) {
            const category = categories.find((item) => item.id === categoriesCriteria.category.id);
            tabs = category.subItems;
        }

        if (!tabs.length) return null;

        if (categoriesCriteria.subCategory) {
            currentSelectedTab = tabs.findIndex((tab) => tab.id === criteria.subCategory.id);
        }

        return (
            <Tabs
                className='search__categoryTabs'
                tabs={tabs}
                currentSelectedTab={currentSelectedTab}
                rootLink={t('navLinks.shop')}
            />
        );
    };
    return (
        <div className='categoryMenu'>
            {isDesktop() ? buildCategoriesMenu(criteria) : buildCategoriesTabs(criteria)}
        </div>
    );
}

export default Filter;
