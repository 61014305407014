import { Typography } from '@material-ui/core';
import useSendContact from 'application/useCases/sendContact';
import useHashScroll from 'hooks/useHashScroll';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ContactForm from 'components/contact/form/Form';

import './homeContact.css';

function HomeContact() {
    const [t] = useTranslation('global');
    const ref = useHashScroll(t('navSections.contact'));
    const { sendContact } = useSendContact();

    const [formStatus, setFormStatus] = useState({
        isSent: false,
        isSuccess: null,
    });

    const handleSubmitForm = (values) =>
        sendContact(values)
            .then(() => setFormStatus({ isSent: true, isSuccess: true }))
            .catch(() => setFormStatus({ isSent: true, isSuccess: false }));

    return (
        <section ref={ref} id={t('navSections.contact')} className='homeContact'>
            <div className='standard-container homeContact__container'>
                {formStatus.isSent ? (
                    <div className='homeContact__resultMessageContainer'>
                        <Typography
                            component='h3'
                            className='homeContact__resultMessageTitle'
                            variant='h1'
                        >
                            {formStatus.isSuccess
                                ? t('homeContact.successMessageTitle')
                                : t('homeContact.errorMessageTitle')}
                        </Typography>
                        <Typography
                            component='p'
                            className='homeContact__resultMessageSmallText'
                            variant='body2'
                        >
                            {formStatus.isSuccess
                                ? t('homeContact.successMessageSmallText')
                                : t('homeContact.errorMessageSmallText')}
                        </Typography>
                    </div>
                ) : (
                    <>
                        <div className='homeContact__textContent'>
                            <Typography variant='h1' component='h2' className='homeContact__title'>
                                {t('homeContact.title')}
                            </Typography>
                            <Typography
                                variant='body1'
                                component='p'
                                className='homeContact__description'
                            >
                                {t('homeContact.description')}
                            </Typography>
                        </div>

                        <ContactForm handleSubmit={handleSubmitForm} />
                    </>
                )}
            </div>
        </section>
    );
}

export default HomeContact;
