import { useAuth } from "infrastructure/services/AuthService";
import useAuthStorage from "infrastructure/services/authStorageAdapter";
import { useNotifier } from "infrastructure/services/notifier";


export default function useLogin() {

    const BAD_REQUEST_HTTP_STATUS = "404";
    const UNAUTHORIZED_HTTP_STATUS = "400";
    const auth = useAuth();
    const storage = useAuthStorage();
    const notifier = useNotifier();

    async function login(userCredentials) {
        try {
            const user = await auth.login(userCredentials);
            storage.saveAuth({ user, token: user.token });
        } catch (e) {
            if (e.message === UNAUTHORIZED_HTTP_STATUS) {
                notifier.notifyError('login.notActivatedAccountError');
            }
            else if (e.message === BAD_REQUEST_HTTP_STATUS) {
                notifier.notifyError('login.credentialsError');
            }
            else {
                notifier.notifyError('login.internalServerError');

            }
            throw e;
        }
    }

    return {
        login
    };
}