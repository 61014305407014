import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import './privacy.css';

function Privacy() {
    const [t] = useTranslation('global');

    return (
        <section id='privacy'>
            <div className='standard-container'>
                <Typography component='h2' variant='h3' color='primary' className='privacy__title'>
                    {t('privacy.title')}
                </Typography>
                <Typography
                    component='h6'
                    variant='body1'
                    color='primary'
                    className='privacy__subtitle'
                >
                    {t('privacy.subtitle1')}
                </Typography>
                <Typography component='p' variant='body1' color='primary' className='privacy__text'>
                    {t('privacy.text1')}
                </Typography>
                <Typography
                    component='h6'
                    variant='body1'
                    color='primary'
                    className='privacy__subtitle'
                >
                    {t('privacy.subtitle2')}
                </Typography>
                <Typography component='p' variant='body1' color='primary' className='privacy__text'>
                    {t('privacy.text2')}
                </Typography>
            </div>
        </section>
    );
}

export default Privacy;
