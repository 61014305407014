import { CircularProgress } from '@material-ui/core';
import useActivateAccount from 'application/useCases/activateAccount';
import { lazy, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import UserModal from 'components/ui/userModal/UserModal';

function Modal() {
    const ErrorContent = lazy(() => import('../errorContent/ErrorContent'));
    const SuccessContent = lazy(() => import('../successContent/SuccessContent'));
    const navigate = useNavigate();
    const [t] = useTranslation('global');
    const { activationCode } = useParams();
    const { activateAccount } = useActivateAccount();
    const [loading, setLoading] = useState(false);
    const [activationResult, setActivationResult] = useState({ success: false, reason: '' });

    useEffect(() => {
        setLoading(true);
        activateAccount(activationCode)
            .then(() => setActivationResult({ success: true, reason: '' }))
            .catch((e) => {
                setActivationResult({ success: false, reason: t(e.message) });
            })
            .finally(setLoading(false));
    }, []);

    const handleSuccess = () => navigate(t('navLinks.signIn'));
    const handleRetry = (error) => console.error(error);

    return (
        <UserModal title={t('activateAccount.title')}>
            {loading ? (
                <CircularProgress />
            ) : activationResult.success ? (
                <SuccessContent onSuccess={handleSuccess} />
            ) : (
                <ErrorContent errorText={activationResult.reason} onError={handleRetry} />
            )}
        </UserModal>
    );
}

export default Modal;
