import authReducer, { initialState as authInitialState } from "infrastructure/reducers/auth";
import { STORE_KEY as AUTH_STORE_KEY } from "infrastructure/reducers/auth/constants";
import cartReducer, { initialState as cartInitialState } from "infrastructure/reducers/cart";
import { STORE_KEY as CART_STORE_KEY } from "infrastructure/reducers/cart/constants";
import { createContext, useCallback, useContext, useReducer } from "react";


const StoreContext = createContext({});
export const useStore = () => useContext(StoreContext);


const initializer =
    (key) => (initial) => {
        const stored = localStorage.getItem(key);
        if (!stored) return initial;
        return JSON.parse(stored);
    };

const combineDispatch = (...dispatches) => (action) =>
    dispatches.forEach((dispatch) => dispatch(action));

export function StoreProvider({ children }) {

    const [authState, authDispatch] = useReducer(authReducer, authInitialState, initializer(AUTH_STORE_KEY));
    const [cartState, cartDispatch] = useReducer(cartReducer, cartInitialState, initializer(CART_STORE_KEY));

    const combinedDispatch = useCallback(combineDispatch(authDispatch, cartDispatch), [authDispatch, cartDispatch]);

    return (
        // eslint-disable-next-line react/jsx-filename-extension, react/jsx-no-constructed-context-values
        <StoreContext.Provider value={[
            { [AUTH_STORE_KEY]: authState, [CART_STORE_KEY]: cartState },
            combinedDispatch
        ]}> {children} </StoreContext.Provider>
    );
}