import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import './tags.css';

function Tags({ tags }) {
    const [t] = useTranslation('global');
    return (
        <div className='productTags__container'>
            <Typography component='h6' className='productTags__title' variant='body2'>
                {t('productDetail.titleTags')}
            </Typography>
            <div className='productTags__tags'>
                {tags.map((tag) => (
                    <div className='productTags__tag'>{tag}</div>
                ))}
            </div>
        </div>
    );
}

export default Tags;
