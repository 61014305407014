import useCartStorage from "infrastructure/services/cartStorageAdapter";

export default function useAddToCart() {

    const storage = useCartStorage();


    function addToCart(cartItem) {
        // if logged save to server
        // save to storage
        return storage.addToCart(cartItem);

    }

    return {
        addToCart
    };
}