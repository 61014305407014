import { Button } from '@material-ui/core';
import useLogin from 'application/useCases/login';
import { useFormik } from 'formik';
import { useCallback, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { object, string } from 'yup';

import ModalLink from 'components/ui/modalLink/ModalLink';
import PasswordField from 'components/ui/passwordField/StyledPasswordField';
import StyledUserForm from 'components/ui/styledUserForm/StyledUserForm';
import TextField from 'components/ui/textField/TextField';

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;

function Form() {
    const [validateOnChange, setValidateOnChange] = useState(false);
    const { login } = useLogin();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [t] = useTranslation('global');
    const navigate = useNavigate();

    const validationSchema = object({
        email: string().email(t('login.emailInvalidError')).required(t('login.emailRequiredError')),
        password: string()
            .required(t('login.passwordRequiredError'))
            .min(8, t('login.passwordMinError'))
            .matches(passwordRules, t('login.passwordIncludesError')),
    });

    const handleSumit = useCallback(
        (values, setSubmitting) => {
            if (!executeRecaptcha) {
                console.log('Execute recaptcha not yet available');
                setSubmitting(false);
                return;
            }
            const { email, password } = values;
            executeRecaptcha('login').then((recaptcha) => {
                login({ email, password, recaptcha })
                    .then(() => {
                        navigate(location.state?.from?.pathname || t('navLinks.home'), {
                            state: { ...location.state?.from?.state },
                            replace: true,
                        });
                    })
                    .catch((e) => console.error(e))
                    .finally(() => setSubmitting(false));
            });
        },
        [executeRecaptcha],
    );

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validateOnChange,
        validationSchema,
        onSubmit: (values, { setSubmitting }) => handleSumit(values, setSubmitting),
    });

    return (
        <StyledUserForm
            onSubmit={(event) => {
                setValidateOnChange(true);
                formik.handleSubmit(event);
            }}
            noValidate
        >
            <div className='userForm__fields'>
                <TextField
                    id='email'
                    light
                    type='email'
                    label={t('login.emailInput')}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={formik.touched.email ? formik.errors.email : ''}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    margin='dense'
                    variant='outlined'
                    fullWidth
                    disabled={formik.isSubmitting}
                    required
                />
                <PasswordField
                    id='password'
                    label={t('login.passwordInput')}
                    margin='dense'
                    fullWidth
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={formik.touched.password ? formik.errors.password : ''}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    disabled={formik.isSubmitting}
                    light
                    required
                />
            </div>
            <div className='lnkPasswordRecover'>
                <ModalLink to={t('navLinks.recoverPassword')}>
                    {t('login.recoverPasswordText')}
                </ModalLink>
            </div>
            <Button className='userForm__btn' variant='contained' color='primary' type='submit'>
                {t('login.signInBtn')}
            </Button>
        </StyledUserForm>
    );
}

export default Form;
