const PicturesData = {
    itemsLabel: 'imagenes productos',
    titleModule: 'gestión',
    listItemsLabel: 'listado',
    
    addItem: 'añadir',
    actionUpload: 'subir',
    actionSave: 'guardar',
    //fields model    

  }
  export default PicturesData;