import React from 'react'
import { useLocation } from 'react-router-dom'
import useOnPageLoad from './useOnPageLoad';

function useHashScroll(hash) {
    const location = useLocation()
    const { hash: urlHash, pathname } = location

    const ref = React.useRef(null);
    const isPageLoaded = useOnPageLoad();

    React.useEffect(() => {
        if (isPageLoaded && pathname === `/${hash}` && ref.current) {
            const yOffset = -50;
            window.scrollTo({ top: ref.current.offsetTop + yOffset, behavior: 'instant' });
        }
    }, [
        pathname,
        urlHash,
        ref,
        hash,
        isPageLoaded
    ]);

    return ref;
}

export default useHashScroll