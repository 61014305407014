import NotAvailable from 'pages/notAvailable/NotAvailable';
import Privacy from 'pages/privacy/Privacy';
import Product from 'pages/product/Product';
import Shop from 'pages/shop/Shop';
import { useTranslation } from 'react-i18next';
import { Outlet, Route, Routes, useLocation } from 'react-router-dom';

import ActivateAccount from 'components/account/activate/modal/Modal';
import ChangePassword from 'components/account/changePassword/modal/Modal';
import Login from 'components/account/login/modal/Modal';
import PasswordRecovery from 'components/account/recovery/modal/Modal';
import Register from 'components/account/register/modal/Modal';
import Layout from 'components/ui/layout/Layout';

import './App.css';
import './fonts/Mulish/Mulish-Bold.ttf';
import './fonts/NotoSans/NotoSans-Regular.ttf';
import Categories from './pages/categories/Categories';
import Home from './pages/home/Home';

function App() {
    const location = useLocation();
    const previousLocation = location.state?.previousLocation;
    const [t] = useTranslation('global');

    return (
        <>
            {/*      <ScrollToTop /> */}
            <Routes location={previousLocation || location}>
                <Route
                    path='/'
                    element={
                        process.env.NODE_ENV === 'production' ? (
                            <NotAvailable />
                        ) : (
                            <Layout>
                                <Outlet />
                            </Layout>
                        )
                    }
                >
                    <Route exact path='/' element={<Home />} />
                    <Route exact path={t('navLinks.signIn')} element={<Login />} />
                    <Route exact path={t('navLinks.signUp')} element={<Register />} />
                    <Route exact path={t('navLinks.categories')} element={<Categories />} />
                    <Route path={`${t('navLinks.shop')}`}>
                        <Route exact path=':categorySlug/:categoryCode' element={<Shop />} />
                        <Route
                            exact
                            path=':categorySlug/:subCategorySlug/:subCategoryCode'
                            element={<Shop />}
                        />
                        <Route
                            exact
                            path={`:categorySlug${t('navLinks.product')}/:productSlug/:productCode`}
                            element={<Product />}
                        />
                        <Route
                            exact
                            path={`:categorySlug/:subCategorySlug${t(
                                'navLinks.product',
                            )}/:productSlug/:productCode`}
                            element={<Product />}
                        />
                        <Route path=':anything?' element={<Shop />} />
                    </Route>
                    <Route
                        exact
                        path={t('navLinks.recoverPassword')}
                        element={<PasswordRecovery />}
                    />
                    <Route exact path='/change-password/:resetCode' element={<ChangePassword />} />

                    <Route
                        exact
                        path='/email-verified/:activationCode'
                        element={<ActivateAccount />}
                    />
                    <Route exact path='/privacidad' element={<Privacy />} />
                    <Route
                        exact
                        path={`${t('navLinks.activateAccount')}/:activationCode`}
                        element={<ActivateAccount />}
                    />
                    {/* check it is a category <Route exact path='/:category?/:subCategory?' element={<Shop}/> */}
                    <Route element={<Home />} />
                </Route>
            </Routes>
            {previousLocation && (
                <Routes>
                    <Route path={t('navLinks.signIn')} element={<Login />} />
                    <Route path={t('navLinks.signUp')} element={<Register />} />
                    <Route
                        exact
                        path='/email-verified/:activationCode?'
                        element={<ActivateAccount />}
                    />
                    <Route
                        exact
                        path={t('navLinks.recoverPassword')}
                        element={<PasswordRecovery />}
                    />
                </Routes>
            )}
        </>
    );
}

export default App;
