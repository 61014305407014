import { Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import UiSelect from '@material-ui/core/Select';
import CheckIcon from '@material-ui/icons/Check';
import FilterListIcon from '@material-ui/icons/FilterList';

import './select.css';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function Select({ options, value, onChange }) {
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        onChange(value);
    };

    return (
        <div className='orderSelect' style={{ background: '#fafafa' }}>
            <FormControl sx={{ m: 1, width: 300, mt: 3, background: '#fafafa' }}>
                <UiSelect
                    value={value}
                    onChange={handleChange}
                    input={<OutlinedInput />}
                    sx={{ backgroundColor: 'white' }}
                    MenuProps={MenuProps}
                    inputProps={{ 'aria-label': 'Without label' }}
                    renderValue={(value) => {
                        return (
                            <Typography className='orderSelect__input'>
                                <FilterListIcon />
                                Ordenado por:{' '}
                                <strong>
                                    {options.find((option) => option.value == value)?.label}
                                </strong>
                            </Typography>
                        );
                    }}
                >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            {option.value === value ? <Checkbox icon={<CheckIcon />} /> : null}
                        </MenuItem>
                    ))}
                </UiSelect>
            </FormControl>
        </div>
    );
}

export default Select;
