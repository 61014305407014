import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import UiMenu from 'components/ui/menu/Menu';

import '../categoryMenu.css';

function MainMenuView({ categories, currentCategoryId, rootLink }) {
    const [t] = useTranslation('global');
    return (
        <UiMenu className='categoryMenu'>
            <Typography className='categoryMenu__title' variant='body2' component='h6'>
                {t('categoriesMenu.title')}
            </Typography>
            <UiMenu.Options>
                {categories.map((item) => (
                    <UiMenu.Option
                        className={
                            currentCategoryId && item.id === currentCategoryId
                                ? 'active'
                                : 'inactive'
                        }
                        key={`category-${item.id}`}
                        link={`${rootLink}${item.link}`}
                        selectable={false}
                    >
                        {item.label}
                    </UiMenu.Option>
                ))}
            </UiMenu.Options>
        </UiMenu>
    );
}

export default MainMenuView;
