const CategoriesMenu = [
    {
        label: 'Regalos de aniversario',
        link: 'regalos-de-aniversario',
        id_picture: 'anniversary.png',
        childs:[
            {
                label: 'pendientes',
                link: 'pendientes',
                id_picture: 'cat_arte_decoracion.webp'
            },
            {
                label: 'anillos',
                link: 'anillos',
                id_picture: 'cat_arte_decoracion.webp'
            }
        ]
    },
    {
        label: 'Regalos para él',
        link: 'regalos-para-el',
        id_picture: 'giftsforhim.png',
        childs: [
            {
                label: 'jarrones',
                link: 'jarrones',
                id_picture: 'cat_arte_decoracion.webp'
            },
            {
                label: 'ceniceros',
                link: 'ceniceros',
                id_picture: 'cat_arte_decoracion.webp'
            }
        ]
    },
    {
        label: 'Regalos para niños',
        link: 'regalos-para-ninos',
        id_picture: 'kids.png',
        childs: [
            {
                label: 'jarrones',
                link: 'jarrones',
                id_picture: 'cat_arte_decoracion.webp'
            },
            {
                label: 'ceniceros',
                link: 'ceniceros',
                id_picture: 'cat_arte_decoracion.webp'
            }
        ]
    },
    {
        label: 'Star Wars',
        link: 'star-wars',
        textAlign: 'right',
        id_picture: 'starwars.png',
        childs:[
            {
                label: 'clasicos',
                link: 'clasicos',
                id_picture: 'cat_arte_decoracion.webp'
            },
            {
                label: 'modernos',
                link: 'modernos',
                id_picture: 'cat_arte_decoracion.webp'
            }
        ]
    }
]

export default CategoriesMenu