import { Button } from '@material-ui/core';
import useChangePassword from 'application/useCases/changePassword';
import { useFormik } from 'formik';
import { useCallback, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { PASSWORD_VALIDATION_RULES } from 'utils/constants';
import { object, string } from 'yup';

import PasswordField from 'components/ui/passwordField/StyledPasswordField';
import StyledUserForm from 'components/ui/styledUserForm/StyledUserForm';

function Form() {
    const [validateOnChange, setValidateOnChange] = useState(false);
    const { changePassword } = useChangePassword();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const { resetCode } = useParams();
    const [t] = useTranslation('global');
    const navigate = useNavigate();

    const validationSchema = object({
        password: string()
            .required(t('changePassword.passwordRequiredError'))
            .min(8, t('changePassword.passwordMinError'))
            .matches(PASSWORD_VALIDATION_RULES, t('changePassword.passwordIncludesError')),
    });

    const handleSumit = useCallback(
        (values, setSubmitting) => {
            if (!executeRecaptcha) {
                console.error('Execute recaptcha not yet available');
                setSubmitting(false);
                return;
            }
            const { password } = values;
            executeRecaptcha('reset').then((recaptcha) => {
                changePassword({ password, recaptcha, resetCode })
                    .then(() => navigate(t('navLinks.signIn')))
                    .catch((e) => console.error(e))
                    .finally(() => setSubmitting(false));
            });
        },
        [executeRecaptcha],
    );

    const formik = useFormik({
        initialValues: {
            password: '',
        },
        validateOnChange,
        validateOnBlur: false,
        validationSchema,
        onSubmit: (values, { setSubmitting }) => handleSumit(values, setSubmitting),
    });

    return (
        <StyledUserForm
            onSubmit={(event) => {
                setValidateOnChange(true);
                formik.handleSubmit(event);
            }}
        >
            <div className='userForm__fields'>
                <PasswordField
                    id='password'
                    label={t('changePassword.passwordInput')}
                    margin='dense'
                    fullWidth
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={formik.touched.password ? formik.errors.password : ''}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    disabled={formik.isSubmitting}
                    light
                    required
                />
            </div>
            <Button className='userForm__btn' variant='contained' color='primary' type='submit'>
                {t('changePassword.sendBtn')}
            </Button>
        </StyledUserForm>
    );
}

export default Form;
