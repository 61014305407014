import { useReducer } from 'react'
//import { render } from 'react-dom'
import { DataService } from '../../domain/services/DataService'
import AppContext from './AppContext'
import AppReducer from './AppReducer'
//import {infoClient } from '../../domain/data/infoClient'
import { ClientService } from '../../domain/services/ClientService'
import {
    ADD_PRODUCT_BASKET,
    CLEAN_BASKET,
    REMOVE_PRODUCT_BASKET,
    SET_ROLE,
    SET_SECTION,
    SET_SESSION,
    SET_SUBSECTION,
    SET_USER,
    /* ADD_UNIT_TO_ITEM_CART, */
    SUBSTRACT_UNIT_TO_ITEM_CART
} from './../types'

//console.log('initAplication');

/* let proxyApi;
if(process.env.NODE_ENV!=='development')
    proxyApi = 'https://api.dreamdo.es/api/'
else
    proxyApi = 'http://localhost:5500/api/' */

const AppState = ({ children }) => {
    let statusApp = null;
    const { infoClient } = DataService;
    let cSubSection = '/';
    // initialize basket
    let basketIni = {
        products: [],
        total: 0
    }
    // get session basket
    let sessionBasket = JSON.parse(localStorage.getItem(`${infoClient.name}__basket_lines`));
    if (sessionBasket)
        Object.assign(basketIni, sessionBasket);

    // get actual section
    /* let currentSection =  JSON.parse(localStorage.getItem(`${infoClient.name}_section`)) || null;
    if(currentSection)
        cSection = currentSection;
 */
    /* let currentSubSection =  JSON.parse(localStorage.getItem(`${infoClient.name}_subsection`)) || null;
    if(currentSubSection)
        cSubSection = currentSubSection; */





    //console.log('valueInit', cSection);

    const initialState = {
        info: infoClient,
        user: null,
        role: 'visit',
        session: null,
        productObserved: {},
        nav: {
            section: 'home',
            subSection: cSubSection
        },
        basket: basketIni
    }

    //console.log('initAplication',initialState);

    const checkRefresh = () => {


        let section = JSON.parse(localStorage.getItem(`${infoClient.name}_section`));
        //console.log('state',section);
        setSection(section);
        let subSection = JSON.parse(localStorage.getItem(`${infoClient.name}_subsection`));
        //console.log('appstate seting subsection', subSection);
        setSubSection(subSection);

        let sessionLang = JSON.parse(localStorage.getItem(`${infoClient.name}_lang`));

        //let sessionFormLogin = JSON.parse(localStorage.getItem('dreamdo_FormLogin'));

        //** Def lang from storage */
        if (sessionLang) {
            /* if(process.env.REACT_APP_DEBUG_MODE === true){
                //console.log('setting lang', sessionLang);
            } */
            //setLanguage(sessionLang);
            //localStorage.setItem('dreamdo_lang',lang);
        }


    }

    const persistData = (data, value) => {
        localStorage.setItem(data, value);
    }

    const checkToken = token => {
        //console.log('checktoken',token);


        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("authorization", token);
        //let urlencoded = new URLSearchParams();
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,

            redirect: 'follow'
        };

        ClientService.checkToken(requestOptions)
            .then(data => {
                //console.log('resp',data)
                if (data.status) {
                    setUser(data.user);
                    setRole(data.user.role);
                    setSession('active');
                }
            });


        /*  fetch(`${proxyApi}client/checkToken`, requestOptions)
                       .then(response => response.json())
                       .then(dadesAcces => {
                           console.log(dadesAcces);
                           if(dadesAcces.status){
                             console.log('setUser',dadesAcces);
                             setUser(dadesAcces.user);
                             setRole(dadesAcces.user.role);
                             setSession('active');
                           }else{
                               console.log('appstate',dadesAcces)
 
                           }
                       })
                       .catch(error => console.log('errorApp', error)); */



    }

    /*     useEffect(() => {
            let token = localStorage.getItem('token');
            if(token)
            checkToken(token);
    
            checkRefresh();
    
    
        },[]) */
    //console.log('loadState', initialState,statusApp);
    const [state, dispatch] = useReducer(AppReducer, !statusApp ? initialState : statusApp); // (reducer encargado del componente, estado inicial)

    const setSection = section => {
        //console.log('infoclient',infoClient)
        //console.log('assing_section',section);
        dispatch({
            type: SET_SECTION,
            payload: section
        })

        persistData(`${infoClient.name}_section`, JSON.stringify(section));
    }

    const setSubSection = section => {
        //console.log('assing_section',section);
        dispatch({
            type: SET_SUBSECTION,
            payload: section
        })

        persistData(`${infoClient.name}_subsection`, JSON.stringify(section));
    }

    const setUser = user => {
        //console.log(user);
        dispatch({
            type: SET_USER,
            payload: user
        })
    }

    const setRole = role => {
        dispatch({
            type: SET_ROLE,
            payload: role
        })
    }

    const setSession = session => {
        dispatch({
            type: SET_SESSION,
            payload: session
        })
    }

    const removeProductBasket = (product) => {
        console.log('removeProductBasket:' + product);

        dispatch({
            type: REMOVE_PRODUCT_BASKET,
            payload: product
        })

    }
    /* const addUnitToItemCart = (idProduct) => {        
        dispatch({
            type: ADD_UNIT_TO_ITEM_CART,
            payload: idProduct
        })
    } */
    const substractUnitToItemCart = (idProduct) => {
        dispatch({
            type: SUBSTRACT_UNIT_TO_ITEM_CART,
            payload: idProduct
        })
    }

    const addProductBasket = (product) => {
        dispatch({
            type: ADD_PRODUCT_BASKET,
            payload: product
        })
        //console.log(process.env.REACT_APP_CLIENT);
        //persistData(`${process.env.REACT_APP_CLIENT}__basket_lines`,JSON.stringify(state.basket));
    }

    const cleanBasket = () => {
        dispatch({
            type: CLEAN_BASKET
        })
    }

    return (
        <AppContext.Provider
            value={{
                info: state.info,
                user: state.user,
                role: state.role,
                session: state.session,
                nav: state.nav,
                basket: state.basket,
                productObserved: state.productObserved,
                setSection,
                setSubSection,
                setUser,
                setRole,
                setSession,
                removeProductBasket,
                addProductBasket,
                /* addUnitToItemCart, */
                substractUnitToItemCart,
                cleanBasket
            }}
        >{children}
        </AppContext.Provider>
    );
}

export default AppState