import { useEffect, useState } from 'react';

function useOnPageLoad() {
    const [isPageLoaded, setIsPageLoaded] = useState(false);

    // This will run one time after the component mounts
    useEffect(() => {
        const onPageLoad = () => {
            setIsPageLoaded(true);
        };

        // Check if the page has already loaded
        if (document.readyState === 'complete') {
            onPageLoad();
            return undefined;
        }

        window.addEventListener('load', onPageLoad);
        // Remove the event listener when component unmounts
        return () => window.removeEventListener('load', onPageLoad);

    }, []);

    return isPageLoaded;
};

export default useOnPageLoad;