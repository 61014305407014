import { Checkbox } from '@material-ui/core';
import MuiCard from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Favorite, FavoriteBorder } from '@material-ui/icons';
import { formatPrice } from 'utils/format';

import ProtectedClickEvent from 'components/ui/protectedClickEvent/ProtectedClickEvent';

import './card.css';

function Card({ product, onClickCard, wishList }) {
    const { id, price, title, image } = product;

    return (
        <MuiCard className='product'>
            {wishList && (
                <ProtectedClickEvent>
                    <Checkbox
                        id={`productCard-${id}`}
                        checked={wishList.isInWishList(id)}
                        icon={<FavoriteBorder />}
                        checkedIcon={<Favorite />}
                        classes={{
                            root: 'product_favorite-btn', // class name, e.g. `classes-nesting-root-x`
                            checked: 'product_favorite-btn--selected',
                        }}
                        onChange={() =>
                            wishList.isInWishList(id)
                                ? wishList.removeFromWishList(id)
                                : wishList.addInWishList(id)
                        }
                    />
                </ProtectedClickEvent>
            )}

            <CardActionArea onClick={() => onClickCard(id)}>
                <CardMedia
                    sx={{ height: 140 }}
                    image={image.url}
                    title={image.description}
                    className='product__img'
                />
                <CardContent>
                    <Typography component='h6' className='product__title' variant='h6'>
                        {title}
                    </Typography>
                    <Typography variant='body2' color='secondary' className='product__price'>
                        {formatPrice(price)}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </MuiCard>
    );
}

export default Card;
