const CategoryData = {
    itemsLabel: 'categoría',
    titlePictures: 'gestión categorias',
    titleModels: 'gestión categorías',
    
    products_pictures: 'imagen',
    products_3dmodel: 'modelo 3D',
    actionSave: 'guardar',
    code: 'código',
    rootCategory: 'categoría padre',   
    parent: 'categoría raiz', 
    name: 'nombre',    
    description: 'descripcion',
    
    /* fom add item */
    addItem: 'añadir',
    editItem: 'editar',
    labelName: 'nombre',
    lapelNumProducts: 'productos',
    labelCode: 'código',
    labelDescription: 'descripción',
    labelOrder: 'orden',
    labelPicture: 'imagen',
    labelRootCategory: 'categoría raiz ?',
    actionSelect: 'selecciona',
    actionSave: 'guardar',
    actionCloseDialog: 'tancar',

    /** Message form creations */

    itemCreated: 'creada correctamente',
    itemUpdated: 'actualizada correctamente',
    errorMessage: 'hubo un error en el proceso',
    errorCreation: 'no creada',
    errorNotParent: 'selecciona categoría raiz o selecciona una categoria raiz'

  }

  export default CategoryData;