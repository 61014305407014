import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

export default function RadioButtonsGroup({
    value,
    onChange,
    label,
    name,
    options,
    ariaLabel,
    className,
}) {
    const handleChange = (event) => {
        onChange(event.target.value);
    };

    return (
        <FormControl className={className} component='fieldset'>
            <FormLabel className={`${className}Label`} component='legend'>
                {label}
            </FormLabel>
            <RadioGroup aria-label={ariaLabel} name={name} value={value} onChange={handleChange}>
                {options.map((option) => (
                    <FormControlLabel
                        key={option.value}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
}
