import {
    SET_SECTION,
    SET_SUBSECTION,
    SET_USER,
    SET_ROLE,
    SET_SESSION,
    ADD_PRODUCT_BASKET,
    /* ADD_UNIT_TO_ITEM_CART, */
    SUBSTRACT_UNIT_TO_ITEM_CART,
    REMOVE_PRODUCT_BASKET,
    CLEAN_BASKET
} from './../types'
let valStorage=[];
const state = ( state, action ) => {
    //console.log(state,action);
    const resetLocalStorage = () => {
        localStorage.removeItem(`${process.env.REACT_APP_CLIENT}__basket_lines`);
    }
    const persistStorage = (stateUpdated) => {
        localStorage.setItem(`${process.env.REACT_APP_CLIENT}__basket_lines`,JSON.stringify(stateUpdated));
    }
    let {total} = state.basket;
    let {products} = state.basket;
    let newTotal;
    switch(action.type){
        case SET_SECTION:
            return {
                ...state,
                nav:{
                    ...state.subSection,
                    section: action.payload
                }
            }
        case SET_SUBSECTION:
            /* if(process.env.REACT_APP_DEBUG_MODE === true){
                console.log('resolverSubsection:'+action.payload)
            } */
            //const key = 'subSection'
            return {
                ...state,
                nav:{
                    ...state.nav,
                    subSection: action.payload

                }
            }
        case SET_ROLE:
            return {
                ...state,
                role: action.payload
            }
        case  SET_USER:
            return {
                ...state,
                user: action.payload
            }
        case SET_SESSION:
            return {
                ...state,
                session: action.payload
            }
        case REMOVE_PRODUCT_BASKET:
            let elementsToDelete = 1;
            //let {products} = state.basket;
            //let {total} = state.basket;
            let itemsCart = Object.keys(products);
            let cntItem=0;
            //let deleteItem;
            console.log('red',products,itemsCart,action.payload,elementsToDelete);
            let newProducts = [];
            itemsCart.map(item => {
                console.log('red',item);
                if (products[item].id === action.payload && elementsToDelete === 1) {
                    // more than one product -
                    console.log('delete',cntItem)
                    elementsToDelete -= 1;
                    total = total-1;


                }else{
                    newProducts.push(products[item])
                }
            });

            valStorage = {
                products: newProducts,
                total
            }
            persistStorage(valStorage);
            //localStorage.setItem(`${process.env.REACT_APP_CLIENT}__basket_lines`,JSON.stringify(valStorage));
            return {
                ...state,
                basket:{
                    products: newProducts,
                    total
                }
            }
            
            
        /* case ADD_PRODUCT_BASKET:
        
        console.log('appreducer',action.payload.materialName);
        let count = state.basket.total + action.payload.units; */

        /** update localstorage info */
        /* valStorage = {
            products: [...state.basket.products, action.payload],
            total:count
        }
        persistStorage(valStorage); */

        /** update localstorage info */

        /** update state info */
        /* return {
            ...state,
            basket:{
                ...state.basket,
                products: [...state.basket.products, action.payload],
                total: count
            }
        } */
        case ADD_PRODUCT_BASKET:
            let indexProductToAdd = products.findIndex(product => {
                return product.id === action.payload.id
                });
            
            if (indexProductToAdd === -1) {
                // product not exits
                let count = state.basket.total + action.payload.units;
                valStorage = {
                    products: [...state.basket.products, action.payload],
                    total:count
                }
                persistStorage(valStorage);
                /** update state info */
                return {
                    ...state,
                    basket:{
                        ...state.basket,
                        products: [...state.basket.products, action.payload],
                        total: count
                    }
                }

                //break;
            }else{
                //product exist
                let count = state.basket.total + action.payload.units;
                
                products[indexProductToAdd].units += action.payload.units;
                //let {total} = state.basket;
                newTotal = total +=action.payload.units;
                valStorage = {
                    products: products,
                    total: newTotal
                }
                persistStorage(valStorage);
                return {
                    ...state,
                    basket:{
                        ...state,
                        ...state.basket,
                        products,
                        total: newTotal
                    }
                }
            }
            

            /** update localstorage info */

            /** update localstorage info */

            /** update state info */
        /* case ADD_UNIT_TO_ITEM_CART:
            let indexProductToAdd = products.findIndex(product => {
                return product.id === action.payload
                });
            
            if (indexProductToAdd === -1) {
                resetLocalStorage();
                break;
            }else{
                products[indexProductToAdd].units +=1;
                newTotal = total +=1;
                valStorage = {
                    products: products,
                    total: newTotal
                }
                persistStorage(valStorage);
    
                return {
                    ...state,
                    basket:{
                        ...state,
                        ...state.basket,
                        products,
                        total: newTotal
                    }
                }
            }   */

        case SUBSTRACT_UNIT_TO_ITEM_CART:
            let indexProductToDelete = products.findIndex(product => {
                return product.id === action.payload
                });
            products[indexProductToDelete].units -=1;
            //let {total} = state.basket;
            newTotal = total -=1;
            valStorage = {
                products: products,
                total: newTotal
            }
            persistStorage(valStorage);

            return {
                ...state,
                basket:{
                    ...state,
                    ...state.basket,
                    products,
                    total: newTotal
                }
            }
        case CLEAN_BASKET:
            valStorage = {
                products: [],
                total: 0
            }
            persistStorage(valStorage);
            return {
                ...state,
                basket: {
                    products: [],
                    total: 0
                }
            }
        default:
            return state
}
}

export default state