import { Typography } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Pagination } from '@material-ui/lab';
import useViewport from 'hooks/useViewport';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CategoryFilter from 'components/category/filter/Filter';
import ProductList from 'components/product/list/List';
import ModalIconButton from 'components/ui/modalIconButton/ModalIconButton';

import FilterModal from './filter/modal/Modal';
import OrderSelect from './order/select/Select';
import './search.css';

function Search({ filters, categories, results, onChangeFilter, orderOptions }) {
    console.log("products", results)
    const [t] = useTranslation('global');
    const { isDesktop } = useViewport();

    return (
        <div className='search__container'>
            <div className='search__panel'>
                <CategoryFilter categories={categories} isDesktop={isDesktop} criteria={filters} />
            </div>
            <div className='search__results'>
                <div className='search__headerResults'>
                    <Typography variant='body2' component='p' className='search__numResults'>
                        {t('shop.numResults', { num: results.totalItems })}
                    </Typography>

                    {isDesktop() ? (
                        <OrderSelect
                            options={orderOptions}
                            value={filters.order}
                            onChange={(value) => onChangeFilter('order', value)}
                        />
                    ) : (
                        <ModalIconButton
                            modal={(props) => (
                                <FilterModal
                                    filters={filters}
                                    onChangeFilter={onChangeFilter}
                                    orderOptions={orderOptions}
                                    {...props}
                                />
                            )}
                        >
                            <FilterListIcon />
                        </ModalIconButton>
                    )}
                </div>
                <ProductList className='search__productList' products={results.items} />

                <div className='search__pagination'>
                    <Pagination
                        count={results.totalPages}
                        siblingCount={isDesktop() ? 2: 0}
                        shape='rounded'
                        color='primary'
                        page={filters.page}
                        onChange={(_, value) => {
                            console.log('firstpage', value);
                            onChangeFilter('page', value);
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default Search;
