import { Button, Typography } from '@material-ui/core';
import useRecoverPassword from 'application/useCases/recoverPassword';
import { useFormik } from 'formik';
import { useCallback, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { object, string } from 'yup';

import StyledUserForm from 'components/ui/styledUserForm/StyledUserForm';
import TextField from 'components/ui/textField/TextField';

function Form() {
    const [validateOnChange, setValidateOnChange] = useState(false);
    const { recoverPassword } = useRecoverPassword();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [t] = useTranslation('global');
    const navigate = useNavigate();

    const validationSchema = object({
        email: string()
            .email(t('passwordRecovery.emailInvalidError'))
            .required(t('passwordRecovery.emailRequiredError')),
    });

    const handleSumit = useCallback(
        (values, setSubmitting) => {
            if (!executeRecaptcha) {
                console.error('Execute recaptcha not yet available');
                setSubmitting(false);
                return;
            }
            const { email } = values;
            executeRecaptcha('recovery').then((recaptcha) => {
                recoverPassword({ email, recaptcha })
                    .then(() => navigate(t('navLinks.signIn')))
                    .catch((e) => console.error(e))
                    .finally(() => setSubmitting(false));
            });
        },
        [executeRecaptcha],
    );

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validateOnChange,
        validateOnBlur: false,
        validationSchema,
        onSubmit: (values, { setSubmitting }) => handleSumit(values, setSubmitting),
    });

    return (
        <StyledUserForm
            onSubmit={(event) => {
                setValidateOnChange(true);
                formik.handleSubmit(event);
            }}
            noValidate
        >
            <div className='userForm__fields'>
                <TextField
                    id='email'
                    light
                    type='email'
                    label={t('passwordRecovery.emailInput')}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={formik.touched.email ? formik.errors.email : ''}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    margin='dense'
                    variant='outlined'
                    fullWidth
                    disabled={formik.isSubmitting}
                    required
                />
            </div>
            <Typography component='p' variant='body1' color='secondary'>
                {t('passwordRecovery.text')}
            </Typography>
            <Button className='userForm__btn' variant='contained' color='primary' type='submit'>
                {t('passwordRecovery.sendBtn')}
            </Button>
        </StyledUserForm>
    );
}

export default Form;
