const Countries = {
    ES: {
        ES: 'españa',
        FR: 'francia'
    },
    CA: {
        ES: 'espanya',
        FR: 'frança'
    }
}

export default Countries