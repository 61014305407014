import { useCategory } from "infrastructure/services/CategoriesService";

export default function useRetrieveMenuCategories() {

    const categoryService = useCategory();

    const mapItem = (category, parentSlug, parentId) => {
        const { _id, name, childs, slug } = category;
        if (!_id) throw new Error("Error mapping categories");

        const link = `${parentSlug}/${slug}/${_id}`;
        const subItems = childs?.map((child) => mapItem(child, `${parentSlug}/${slug}`, _id));
        return {
            id: _id,
            label: name,
            link,
            subItems,
            parentItem: parentId
        };
    };

    const mapItems = (items) => items?.map((item) => mapItem(item, ''));

    async function retrieveMenuCategories() {

        try {

            const params = {
                menu: true,
                root: true,
                includeChilds: true

            }
            const categories = await categoryService.getCategories(params);
            return mapItems(categories);

        } catch (e) {
            return [];
        }

    }


    return { retrieveMenuCategories };
}