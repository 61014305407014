import { useProduct } from "infrastructure/services/ProductsService";

export default function useRetrieveProducts() {

    const productService = useProduct();

    const mapProduct = (product) => ({
        // eslint-disable-next-line no-underscore-dangle
        id: product._id,
        title: product.name,
        price: product.price || 0,
        category: product.category,
        subcategory: product.subcategory,
        slug: product.slug,
        image: {
            url: product.images?.fileUrl,
            description: product.images?.seoName,
        },
    });

    async function retrieveProducts(filters) {

        try {
            const products = await productService.getProducts(filters);
            return {
                items: products.items.map(item => mapProduct(item)),
                totalItems: products.total_items,
                totalPages: products.total_pages
            }

        } catch (e) {
            return {
                items: [],
                totalItems: 0,
                totalPages: 1
            }
        }
    }


    return { retrieveProducts };
}