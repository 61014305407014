//const data
import NavLinks from '../data/NavLinks';
import Countries from '../data/config/Countries';
import CategoriesMenu from '../data/CategoriesMenu';
import Sections from '../data/Sections';
import sectionCategory from '../data/sections/Category';
import sectionCheckout from '../data/sections/Checkout';
import sectionHome from '../data/sections/Home';
import sectionUserProfile from '../data/sections/UserProfile';
import sectionShop from '../data/sections/Shop';
import sectionProduct from '../data/sections/Product';
import infoClient from '../data/infoClient';

import { optionsMenu } from '../data/optionsMenu';
// dashboardTexts
import CategoryData from '../data/dashboard/CategoryData'
import Models3dData from '../data/dashboard/Models3dData'
import MoldsData from '../data/dashboard/MoldsData'
import FinishColorsData from '../data/dashboard/FinishColorsData' 
import PicturesData from '../data/dashboard/PicturesData'
import ProductsData from '../data/dashboard/ProductsData'
import ResourcesData from '../data/dashboard/ResourcesData'

export const DataService = {
    infoClient,
    optionsMenu,
    NavLinks,
    CategoriesMenu,
    Sections,
    sectionsContent: {
        sectionCategory,
        sectionHome,
        sectionShop,
        sectionProduct,
        sectionCheckout,
        sectionUserProfile
    },
    dashboard: {
        CategoryData,
        Models3dData,
        MoldsData,
        FinishColorsData,
        PicturesData,
        ProductsData,
        ResourcesData
    },
    generalConfig: {
        Countries
    }
}