import { styled } from '@material-ui/core/styles';

const StyledUserForm = styled('form')({
    '& .userForm__fields': {
        margin: 'auto',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',

        '& > *': {
            marginBottom: '2vh',
            marginTop: '0',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '8px !important',
        },
    },
    '& .userForm__btn': {
        margin: '5.75vh 0 4vh 0',
        padding: '0.875rem 4.5rem',
        borderRadius: '25px',
        border: 'none',
        background: '#3f2188',
        color: '#fff',
    },
});

export default StyledUserForm;
