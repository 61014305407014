import { useTranslation } from 'react-i18next';

import UserModal from 'components/ui/userModal/UserModal';

import ChangePasswordForm from '../form/Form';

function Modal() {
    const [t] = useTranslation('global');

    return (
        <UserModal title={t('changePassword.title')}>
            <ChangePasswordForm />
        </UserModal>
    );
}

export default Modal;
