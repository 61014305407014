import CategoryCard from 'components/category/card/Card'
import { Slide } from 'react-slideshow-image';

import 'react-slideshow-image/dist/styles.css';
import './carousel.css';

function Carousel({ categories, handleClickCard  }) {

    return (
        <div className='carousel__container'>
            <Slide
                duration={5000}
                transitionDuration={500}
                infinite
                indicators
                scale={0.4}
                arrows={false}
                pauseOnHover
                canSwipe={false}
                slidesToShow={2.5}
            >
                {categories.map((category) => (
                    <div key={`carouselCategory-${category.id}`} className='carousel__slide'>
                      <CategoryCard
                      id={`carouselItem-${category.id}`}
                      key={`carouselItem-${category.id}`}
                      category={category}
                      handleClickCard={handleClickCard}
                  />
                    </div>
                ))}
            </Slide>
        </div>
    );
}

export default Carousel;

