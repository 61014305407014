import { ACTION_TYPES } from "./constants";

export const initialState = {
    cartItems: [],
    cartProducts: {},
    cartVariants: {}
};

export default function cartReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.ADD_TO_CART:
            const { product, variant, quantity } = action.payload;
            const foundItemIndex = state.cartItems.findIndex((item) => item.variant === variant.id && item.product === product.id);
            if (foundItemIndex !== -1) {
                return {
                    ...state,
                    cartItems: [
                        ...state.cartItems.slice(0, foundItemIndex),
                        {
                            ...state.cartItems[foundItemIndex],
                            quantity,
                        },
                        ...state.cartItems.slice(foundItemIndex + 1),
                    ]
                }
            }
            return {
                ...state,
                cartItems: [
                    ...state.cartItems,
                    {
                        quantity,
                        product: product.id,
                        variant: variant.id
                    }
                ],
                cartProducts: { ...state.cartProducts, [product.id]: product },
                cartVariants: { ...state.cartVariants, [variant.id]: variant }
            };
        default:
            return state;
    }
}
