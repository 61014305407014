const ResourcesData = {
    itemsLabel: 'colores acabados',
    titleModule: 'gestión',
    listItemsLabel: 'listado',
    titleSection: 'gestión colores acabados',
    titleModels: 'gestión Colores',
    addItem: 'añadir',
    selectItem: 'seleccionar',
    item_to_add: 'color acabado',

    subitem: 'modelo 3D',
    actionSelect: 'listado archivos 3d',
    actionSave: 'guardar',
    actionGenerate: 'guardar color',

    codeColor: 'codigo Hexadecimal',
    fileUnits: 'Unidad',
    surfaceCm2: 'Superficie Cm2',
    volumeCm3: 'Volumen Cm3',
    locked: 'En uso',
    createdAt: 'Creado',
    modelFileName: 'Archivo 3d',
    labelColor: 'nombre color',
    xDimMm: 'Medida X',
    yDimMm: 'Medida Y',
    zDimMm: 'Medida Z',
  }

  export default ResourcesData;