import { useProduct } from "infrastructure/services/ProductsService";

export default function useRetrieveRecommendedProducts() {

    const productService = useProduct();

    const mapProduct = (product) => ({
        // eslint-disable-next-line no-underscore-dangle
        id: product._id,
        title: product.name,
        price: product.price || 0,
        category: product.category,
        subcategory: product.subcategory,
        slug: product.slug,
        image: {
            url: product.images?.fileUrl,
            description: product.images?.seoName,
        },
    });

    async function retrieveRecommendedProducts(id) {

        try {
            const products = await productService.getRecommendedProducts(id);
            return products.items.map(item => mapProduct(item));
        } catch (e) {
            return [];
        }
    }


    return { retrieveRecommendedProducts };
}