import { Typography } from '@material-ui/core';
import useRetrieveCategories from 'application/useCases/retrieveCategories';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import CategoriesList from 'components/category/list/List';

import './categories.css';

function Categories() {
    const [t] = useTranslation('global');
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const { retrieveCategories } = useRetrieveCategories();

    const handleClickCard = (slug) => {
        navigate(`${t('navLinks.shop')}/${slug}`);
    };

    useEffect(() => {
        retrieveCategories().then((categories) => setCategories(categories));
    }, []);
    return (
        <section id='categories'>
            <div className='categories__container standard-container'>
                <Typography variant='h2' component='h2' className='categories__title'>
                    {t('categories.title')}
                </Typography>
                <CategoriesList handleClickCard={handleClickCard} categories={categories} />
            </div>
        </section>
    );
}

export default Categories;
