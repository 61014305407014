import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import './imagesSlider.css';

function ImagesSlider({ images }) {
    const indicators = (index) => <div className='imagesSlider__indicator'>{index + 1}</div>;

    return (
        <div className='imagesSlider__container' key={images}>
            <Slide
                indicators={indicators}
                autoplay={false}
                duration={5000}
                transitionDuration={500}
                scale={0.4}
                slidesToShow={1}
                defaultIndex={0}
                infinite={false}
                arrows={images.length > 1}
            >
                {images.map((image) => (
                    <img key={image.url} src={image.url} alt={image.description} />
                ))}
            </Slide>
        </div>
    );
}

export default ImagesSlider;
