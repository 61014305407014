import { Button } from '@material-ui/core';
import useRegister from 'application/useCases/register';
import { useFormik } from 'formik';
import { useCallback, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { PASSWORD_VALIDATION_RULES } from 'utils/constants';
import { object, ref, string } from 'yup';

import StyledUserForm from 'components/ui/styledUserForm/StyledUserForm';
import TextField from 'components/ui/textField/TextField';

function Form() {
    const [validateOnChange, setValidateOnChange] = useState(false);
    const { register } = useRegister();

    const [t] = useTranslation('global');
    const navigate = useNavigate();
    const location = useLocation();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const validationSchema = object({
        name: string(),
        email: string()
            .email(t('register.emailInvalidError'))
            .required(t('register.emailRequiredError')),
        password: string()
            .required(t('register.passwordRequiredError'))
            .min(8, t('register.passwordMinError'))
            .matches(PASSWORD_VALIDATION_RULES, t('register.passwordIncludesError')),
        confirmedPassword: string()
            .required(t('register.confirmedPasswordRequiredError'))
            .oneOf([ref('password')], t('register.confirmedPasswordNotMatchError')),
    });

    const handleSumit = useCallback(
        (values, setSubmitting) => {
            if (!executeRecaptcha) {
                console.log('Execute recaptcha not yet available');
                setSubmitting(false);
                return;
            }
            const { name, email, password } = values;
            executeRecaptcha('register').then((recaptcha) => {
                register({ name, email, password, recaptcha })
                    .then(() =>
                        setTimeout(() => {
                            navigate(location.state?.from?.pathname || t('navLinks.home'), {
                                state: { ...location.state?.from?.state },
                                replace: true,
                            });
                        }, 4000),
                    )
                    .catch((e) => console.error(e))
                    .finally(() => setSubmitting(false));
            });
        },
        [executeRecaptcha],
    );

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            password: '',
            confirmedPassword: '',
        },
        validateOnChange,
        validateOnBlur: false,
        validationSchema,
        onSubmit: (values, { setSubmitting }) => handleSumit(values, setSubmitting),
    });

    return (
        <StyledUserForm
            onSubmit={(event) => {
                setValidateOnChange(true);
                formik.handleSubmit(event);
            }}
            noValidate
        >
            <div className='userForm__fields'>
                <TextField
                    id='name'
                    light
                    type='name'
                    label={t('register.nameInput')}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={formik.touched.name ? formik.errors.name : ''}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    margin='dense'
                    variant='outlined'
                    fullWidth
                    disabled={formik.isSubmitting}
                />
                <TextField
                    id='email'
                    light
                    type='email'
                    label={t('register.emailInput')}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={formik.touched.email ? formik.errors.email : ''}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    margin='dense'
                    variant='outlined'
                    fullWidth
                    disabled={formik.isSubmitting}
                    required
                />
                <TextField
                    id='password'
                    light
                    type='password'
                    label={t('register.passwordInput')}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={formik.touched.password ? formik.errors.password : ''}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    margin='dense'
                    variant='outlined'
                    fullWidth
                    disabled={formik.isSubmitting}
                    required
                />
                <TextField
                    id='confirmedPassword'
                    light
                    type='password'
                    label={t('register.confirmedPasswordInput')}
                    value={formik.values.confirmedPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                        formik.touched.confirmedPassword ? formik.errors.confirmedPassword : ''
                    }
                    error={
                        formik.touched.confirmedPassword && Boolean(formik.errors.confirmedPassword)
                    }
                    margin='dense'
                    variant='outlined'
                    fullWidth
                    disabled={formik.isSubmitting}
                    required
                />
            </div>
            <Button className='userForm__btn' variant='contained' color='primary' type='submit'>
                {t('register.signUpBtn')}
            </Button>
        </StyledUserForm>
    );
}

export default Form;
