import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ProductCard from 'components/product/card/Card';

import './list.css';

function List({ className, products, wishList }) {
    const navigate = useNavigate();
    const [t] = useTranslation('global');
    const handleClickCard = (id) => {
        const product = products.find((result) => result.id === id);
        let link = t('navLinks.shop');
        if (product.category.slug) link += `/${product.category.slug}`;
        if (product.subcategory.slug) link += `/${product.subcategory.slug}`;
        link += `${t('navLinks.product')}/${product.slug}/${id}`;
        navigate(link);
    };

    return (
        <div className={`productList ${className}`}>
            {products.map((product) => (
                <ProductCard
                    key={`productList-${product.id}`}
                    product={product}
                    onClickCard={handleClickCard}
                    wishList={wishList}
                />
            ))}
        </div>
    );
}

export default List;
