import { Button, CircularProgress, MenuItem } from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';

import StyledTextField from 'components/ui/textField/TextField';

import './form.css';

function Form({ handleSubmit }) {
    const [t] = useTranslation('global');

    const validationSchema = object({
        name: string().max(100, t('homeContact.nameMaxError')),
        email: string().email().required(t('homeContact.emailRequiredError')),
        subject: string()
            .oneOf(Object.keys(t('homeContact.subjectOptions', { returnObjects: true })))
            .required(t('homeContact.subjectRequiredError')),
        message: string()
            .max(500, t('homeContact.messageMaxError'))
            .required(t('homeContact.messageRequiredError')),
    });

    const subjectOptions = Object.entries(
        t('homeContact.subjectOptions', { returnObjects: true }),
    ).map(([key, value]) => ({
        value: key,
        label: value,
    }));

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            subject: '',
            message: '',
        },
        validationSchema,
        onSubmit: (values, { setSubmitting }) => {
            handleSubmit(values).then(() => setSubmitting(false));
        },
    });

    const isFormInvalid = () => JSON.stringify(formik.errors) !== '{}';
    return (
        <form onSubmit={formik.handleSubmit} className='contactForm'>
            <StyledTextField
                id='name'
                label={t('homeContact.nameInput')}
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.touched.name ? formik.errors.name : ''}
                error={formik.touched.name && Boolean(formik.errors.name)}
                margin='dense'
                variant='outlined'
                fullWidth
                className='contactForm__field contactForm__name'
                disabled={formik.isSubmitting}
            />
            <StyledTextField
                id='email'
                label={t('homeContact.emailInput')}
                type='email'
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.touched.email ? formik.errors.email : ''}
                error={formik.touched.email && Boolean(formik.errors.email)}
                margin='dense'
                variant='outlined'
                fullWidth
                className='contactForm__field contactForm__email'
                disabled={formik.isSubmitting}
                required
            />
            <StyledTextField
                select
                id='subject'
                label={t('homeContact.subjectInput')}
                value={formik.values.subject}
                onChange={formik.handleChange('subject')}
                onBlur={formik.handleBlur('subject')}
                helperText={formik.touched.subject ? formik.errors.subject : ''}
                error={formik.touched.subject && Boolean(formik.errors.subject)}
                margin='dense'
                variant='outlined'
                fullWidth
                className='contactForm__field contactForm__subject'
                disabled={formik.isSubmitting}
                required
            >
                {subjectOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </StyledTextField>
            <StyledTextField
                id='message'
                label={t('homeContact.messageInput')}
                minRows='8'
                type='message'
                value={formik.values.message}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.touched.message ? formik.errors.message : ''}
                error={formik.touched.message && Boolean(formik.errors.message)}
                margin='dense'
                variant='outlined'
                fullWidth
                multiline
                className='contactForm__field contactForm__message'
                disabled={formik.isSubmitting}
                required
            />

            <div className='contactform__footer'>
                <Button
                    color='primary'
                    className={`${
                        formik.isSubmitting
                            ? 'contactForm__btn contactForm__loadingBtn'
                            : 'contactForm__btn'
                    }`}
                    variant='contained'
                    onClick={formik.handleSubmit}
                    disabled={formik.isSubmitting || isFormInvalid()}
                >
                    {formik.isSubmitting ? (
                        <CircularProgress size={24} className='contactForm__loading' />
                    ) : (
                        <>{t('homeContact.sendBtn')}</>
                    )}
                </Button>
            </div>
        </form>
    );
}

export default Form;
