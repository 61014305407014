/* eslint-disable react/jsx-props-no-spreading */
import { IconButton } from '@material-ui/core';
import { useState } from 'react';

function ModalIconButton({ children, modal, ...otherProps }) {
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <>
            <IconButton {...otherProps} onClick={() => setModalOpen(true)}>
                {children}
            </IconButton>
            {modalOpen && modal && modal({ open: modalOpen, onClose: () => setModalOpen(false) })}
        </>
    );
}

export default ModalIconButton;
