import { useAuth } from "infrastructure/services/AuthService";
import { useNotifier } from "infrastructure/services/notifier";
import useAuthStorage from "infrastructure/services/authStorageAdapter";

export default function useRegister() {

    const CONFLICT_HTTP_STATUS = "409";

    const auth = useAuth();
    const notifier = useNotifier();
    const storage = useAuthStorage();

    async function register(userData) {
        try {
            const user = await auth.register(userData);
            notifier.notify("register.successMessage");
            storage.saveAuth({ user, token: user.token });
        } catch (e) {
            if (e.message === CONFLICT_HTTP_STATUS) {
                notifier.notifyError('register.userExistsError');
            }
            else {
                notifier.notifyError("register.internalServerError");
            }
            throw e;
        }
    }

    return {
        register
    };
}