import MuiCard from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import './card.css';

function Card({ category, handleClickCard }) {
    const { image, slug, title } = category;

    const styles = {
        backgroundImage: `url(${image?.url})`,
        backgroundSize: 'cover',
    };
    return (
        <MuiCard className='category' style={styles}>
            <CardActionArea onClick={() => handleClickCard(slug)}>
                <CardContent>
                    <Typography component='h3' className='category__title' variant='h3'>
                        {title}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </MuiCard>
    );
}
export default Card;
