const ResourcesData = {
    itemsLabel: 'moldes Imaterialise',
    titleModule: 'gestión',
    listItemsLabel: 'listado',
    titleSection: 'gestión moldes Imaterialise',
    titleModels: 'gestión Modelos',
    addItem: 'añadir',
    selectItem: 'seleccionar',
    item_to_add: 'modelo Imaterialise',
    subitem: 'modelo 3D',
    actionSelect: 'listado archivos 3d',
    actionSave: 'guardar',
    actionGenerate: 'generar molde',

    modelID: 'Id Imaterialise',
    fileUnits: 'Unidad',
    surfaceCm2: 'Superficie Cm2',
    volumeCm3: 'Volumen Cm3',
    locked: 'En uso',
    createdAt: 'Creado',
    modelFileName: 'Archivo 3d',
    moldName: 'nombre molde',
    xDimMm: 'Medida X',
    yDimMm: 'Medida Y',
    zDimMm: 'Medida Z',
  }

  export default ResourcesData;