import { Button, Typography } from '@material-ui/core';
import { useState } from 'react';



import ProductTags from 'components/product/tags/Tags';



import ProductDescription from '../description/Description';
import ImagesSlider from '../imagesSlider/ImagesSlider';
import ProductSelector from '../selector/Selector';
import './detail.css';


function Detail({ product, onAddToCart }) {
    const { id, title, description, tags } = product;
    const [images, setImages] = useState(product.getDefaultVariant().images);

    const handleAddToWishlist = (productId) =>
        console.log(`Added product ${productId} to wishlist`);

    return (
        <div className='productDetail'>
            <ImagesSlider images={images} />
            <div className='productDetail__details'>
                <Typography component='h6' className='productDetail__title' variant='h6'>
                    {title}
                </Typography>
                <ProductSelector
                    onSelect={onAddToCart}
                    onChangeSelection={(variant) => setImages(variant.images)}
                    product={product}
                    Favorite={
                        <Button
                            className='productDetail__favBtn'
                            variant='contained'
                            color='secondary'
                            type='button'
                            onClick={() => handleAddToWishlist(id)}
                        >
                            Add to wishlist
                        </Button>
                    }
                />
            </div>
            <ProductDescription content={description} />
            <ProductTags tags={tags} />
        </div>
    );
}

export default Detail;