import { useAuth } from "infrastructure/services/AuthService";
import { useNotifier } from "infrastructure/services/notifier";

export default function useChangePassword() {

    const auth = useAuth();
    const notifier = useNotifier();

    async function changePassword(params) {

        try {
            await auth.changePassword(params);
        } catch (e) {
            notifier.notifyError("Error recovering password");
            throw e;
        }
    }
    return {
        changePassword
    };
}