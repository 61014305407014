import { useMailer } from "infrastructure/services/MailService";

export default function useSendContact() {

    const mailService = useMailer();

    async function sendContact(contact) {
        try {
            await mailService.sendContactForm(contact);
        } catch (error) {
            throw new Error("message not sent");
        }
    }

    return { sendContact };
}