import { ACTION_TYPES, STORE_KEY } from "infrastructure/reducers/auth/constants";
import { useStore } from "./store";

function useAuthStorage() {
    const [state, dispatch] = useStore();

    const saveAuth = (newAuth) => {
        dispatch(
            {
                type: ACTION_TYPES.SET_AUTH,
                payload: newAuth
            });
        localStorage.setItem(STORE_KEY, JSON.stringify(newAuth));

    }

    const clearAuth = () => {
        dispatch(
            {
                type: ACTION_TYPES.DELETE_AUTH,
            });
        localStorage.removeItem(STORE_KEY)
    }

    /*     localStorage.removeItem('section') //  historial navegacio
        localStorage.removeItem('token') */

    return {
        saveAuth,
        clearAuth,
        user: state.auth?.user
    };
}

export default useAuthStorage;
