import { useAuth } from "infrastructure/services/AuthService";

export default function useActivateAccount() {

    const NOT_FOUND_HTTP_STATUS = "404"; // Codigo cuando es invalido
    const UNAUTHORIZED_HTTP_STATUS = "400"; // Codigo cuando esta caducado

    const auth = useAuth();

    async function activateAccount(activationCode) {
        try {
            await auth.activateAccount(activationCode);
        } catch (e) {
            if (e.message === NOT_FOUND_HTTP_STATUS) {
                throw new Error('activateAccount.notFoundUserError');
            }
            if (e.message === UNAUTHORIZED_HTTP_STATUS) {
                throw new Error('activateAccount.unauthorizedError');
            }

            throw new Error('activateAccount.internalServerError');

        }

    }

    return {
        activateAccount
    };
}