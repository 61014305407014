import { http } from '../../infrastructure/http/http'
let server;
if (process.env.NODE_ENV === 'production') {
    server = process.env.REACT_APP_API_SERVER;
} else {
    server = process.env.REACT_APP_API_SERVER_DEVELOP;
}

const getUserInfo = (requestOptions) => {
    const response = http.post(`${server}/api/client/getUserInfo`, requestOptions);
    return response;
}

const getClientData = (requestOptions) => {
    const response = http.post(`${server}/api/client/getClientData`, requestOptions);
    return response;
}

const loginClient = (requestOptions) => {
    const response = http.post(`${server}/api/client/login`, requestOptions);
    return response;
}

const checkToken = (requestOptions) => {
    const response = http.get(`${server}/api/client/checkToken`, requestOptions);
    return response;
}

const addClientAddress = (requestOptions) => {
    const response = http.post(`${server}/api/client/address`, requestOptions);
    return response;
}

const updateClientData = (requestOptions) => {
    const response = http.put(`${server}/api/client/data`, requestOptions);
    return response;
}

const registerClient = (requestOptions) => {
    const response = http.post(`${server}/api/client/register`, requestOptions);
    return response;
}

const activateAccount = (requestOptions) => {
    const response = http.post(`${server}/api/client/activate-account`, requestOptions);
    return response;
}

const activateResetPassword = (requestOptions) => {
    const response = http.post(`${server}/api/client/requestResetPassword`, requestOptions);
    return response;
}

const checkCodeToChangePassword = (requestOptions) => {
    const response = http.post(`${server}/api/client/checkCodeChangePasswordReset`, requestOptions);
    return response;
}

const changePasswordClient = (requestOptions) => {
    const response = http.post(`${server}/api/client/changePasswordClient`, requestOptions);
    return response;
}






export const ClientRepository = {
    changePasswordClient,
    checkCodeToChangePassword,
    activateResetPassword,
    activateAccount,
    registerClient,
    getClientData,
    updateClientData,
    addClientAddress,
    getUserInfo,
    loginClient,
    checkToken
}