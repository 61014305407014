import useAddToCart from 'application/useCases/addToCart';
import useRetrieveProduct from 'application/useCases/retrieveProduct';
import useRetrieveRecommendedProducts from 'application/useCases/retrieveRecommendedProducts';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ProductDetail from 'components/product/detail/Detail';
import SimilarInterests from 'components/product/similarInterests/SimilarInterests';
import Breadcrumbs from 'components/ui/breadcrumbs/Breadcrumbs';

import './product.css';

function Product() {
    const [t] = useTranslation('global');
    const { productCode } = useParams();

    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [product, setProduct] = useState(null);
    const [similarProducts, setSimilarProducts] = useState([]);
    const { retrieveRecommendedProducts } = useRetrieveRecommendedProducts();
    const { retrieveProduct } = useRetrieveProduct();
    const { addToCart } = useAddToCart();

    const handleAddToCart = (variant, quantity) => addToCart({ variant, quantity, product });

    useEffect(() => {
        retrieveRecommendedProducts(productCode).then((productItems) =>
            setSimilarProducts(productItems),
        );
        retrieveProduct(productCode).then((product) => {
            const { category, subcategory, slug, id } = product;

            setProduct({
                ...product,
                link: `${t('navLinks.shop')}/${category.slug}/${subcategory.slug}${t(
                    'navLinks.product')}/${slug}/${id}`,
            });
            const newBreadcrumbs = [{ label: t('shop.mainBreadcrumb'), link: t('navLinks.shop') }];

            newBreadcrumbs.push({
                label: category.name,
                link: `${t('navLinks.shop')}/${category.slug}/${category.code}`,
            });

            newBreadcrumbs.push({
                label: subcategory.name,
                link: `${t('navLinks.shop')}/${category.slug}/${subcategory.slug}/${
                    subcategory.code
                }`,
            });

            newBreadcrumbs.push({
                label: product.title,
            });

            setBreadcrumbs(newBreadcrumbs);
        });
    }, [productCode]);
    return (
        <section id='product' className='standard-container'>
            {!!breadcrumbs.length && <Breadcrumbs breadcrumbs={breadcrumbs} />}
            {product && <ProductDetail product={product} onAddToCart={handleAddToCart} />}
            <SimilarInterests products={similarProducts} />
        </section>
    );
}

export default Product;
