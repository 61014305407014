import React from 'react'
import { NavLink } from 'react-router-dom'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import Button from '@material-ui/core/Button'

const childrenWithProps = (className, children) =>
  React.Children.map(children, child => {
    // Checking isValidElement is the safe way and avoids a
    // typescript error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { parentClassName: className })
    }
    return child
  })

function Title({ children, parentClassName }) {
  return <div className={`${parentClassName}__title`}>{children}</div>
}

function Option({
  children,
  link,
  selectable,
  onSelect,
  parentClassName,
  className,
  onOptionLinkClicked
}) {
  return (
    <li className={`${parentClassName}__option ${className}`}>
      {link ? (
        <NavLink
          to={link}
          className='cntSubCategoriesMenu'
          onClick={onOptionLinkClicked}
        >
          {children}
        </NavLink>
      ) : (children)}
      {selectable && (
        <ArrowForwardIosIcon
          className={`${parentClassName}__forwardButton`}
          onClick={onSelect}
        />
      )}
    </li>
  )
}

function Options({ parentClassName, children }) {
  return (
    <ul className={`${parentClassName}__list`}>
      {childrenWithProps(parentClassName, children)}
    </ul>
  )
}

function BackButton({ parentClassName, onClick, children }) {
  return <Button className={`${parentClassName}__backButton`} onClick={onClick}>
    <ArrowBackIosIcon />
    <div>{children}</div>
  </Button>
}

function Menu({ className, children }) {
    return childrenWithProps(className, children);
}

Menu.Title = Title
Menu.Options = Options
Menu.Option = Option
Menu.BackButton = BackButton

export default Menu
