import { ACTION_TYPES } from "./constants";

export const initialState = {};
export default function authReducer(state, action) {
    switch (action.type) {
        case ACTION_TYPES.SET_AUTH:
            return action.payload;
        case ACTION_TYPES.DELETE_AUTH:
            return null;
        default:
            return state;
    }
}
