import { useAuth } from "infrastructure/services/AuthService";
import { useNotifier } from "infrastructure/services/notifier";

export default function useRecoverPassword() {

    const NOT_FOUND_HTTP_STATUS = "404";

    const auth = useAuth();
    const notifier = useNotifier();

    async function recoverPassword(params) {

        try {
            await auth.recoverPassword(params);
        } catch (e) {
            if (e.message === NOT_FOUND_HTTP_STATUS) {
                notifier.notifyError('passwordRecovery.notFoundUserError');
            }
            else {
                notifier.notifyError('passwordRecovery.internalServerError');

            }
            throw e;
        }
    }
    return {
        recoverPassword
    };
}