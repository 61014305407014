import { http } from 'infrastructure/http/http';

const appendOrder = (body, orderParam) => {
    switch (orderParam) {
        case 'high-low-price':
            body.append("order_by", "price");
            body.append("order_type", "DESC");
            break;

        // TODO change when relevancy works
        default:
        // case 'low-high-price':
            body.append("order_by", "price");
            body.append("order_type", "ASC");
            break;
/*         default:
            body.append("order_by", "relevancy");
            body.append("order_type", "DESC");
            break; */
    }
}
const getProducts = async (params) => {
    const body = new URLSearchParams();
    body.append("limit", params.limit || 10);
    body.append("page", params.page);
    appendOrder(body, params.order);
    if (params.category) body.append("category", params.category?.id);
    if (params.subCategory) body.append("subCategory", params.subCategory?.id);
    if (params.text) body.append("s", params.text);

    return http.get('products', body);
}

const getProductDetail = async (id) => http.get(`products/${id}`);

const getRecommendedProducts = async (id) => http.get(`products/${id}/recommended`);

export function useProduct() {
    return {
        getProducts,
        getProductDetail,
        getRecommendedProducts
    }
}