export const SET_SECTION = 'SET_SECTION';
export const SET_SUBSECTION = 'SET_SUBSECTION';

export const SET_USER = 'SET_USER'
export const SET_ROLE = 'SET_ROLE'
export const SET_SESSION = 'SET_SESSION'

export const ADD_UNIT_TO_ITEM_CART = 'ADD_UNIT_TO_ITEM_CART'
export const SUBSTRACT_UNIT_TO_ITEM_CART = 'SUBSTRACT_UNIT_TO_ITEM_CART'
export const REMOVE_PRODUCT_BASKET = 'REMOVE_PRODUCT_BASKET'
export const ADD_PRODUCT_BASKET = 'ADD_PRODUCT_BASKET'
export const CLEAN_BASKET = 'CLEAN_BASKET'