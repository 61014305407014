/* eslint-disable react/jsx-props-no-spreading */
import { TextField as MuiTextField, withStyles } from '@material-ui/core';

const TextField = withStyles({
    root: (props) => ({
        height: 'auto',
        color: `${props.light ? 'black' : 'white'}`,
        background: 'transparent',
        '& input': {
            height: '2rem',
        },
        '& input.Mui-disabled': {
            opacity: '0.6',
        },
        '& textarea': {
            color: `${props.light ? 'black' : 'white'}`,
        },
        '& textarea.Mui-disabled': {
            opacity: '0.6',
        },
        '& label.Mui-focused': {
            color: `${props.light ? '#3f2188' : 'white'}`,
        },
        '& label.Mui-disabled': {
            color: `${props.light ? 'black' : '#ffffffe6'}`,
        },
        '& label:not(.MuiInputLabel-shrink)': {
            transform: 'translate(14px, 19px) scale(1)',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: `${props.light ? '' : 'white'}`,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: `${props.light ? '' : 'white'}`,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: `${props.light ? '' : 'white'}`,
            },
            '&.Mui-focused fieldset': {
                borderColor: `${props.light ? '' : 'white'}`,
            },
            '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                borderColor: `${props.light ? '' : 'white'}`,
            },
        },
        '& .MuiSelect-root': {
            color: `${props.light ? 'black' : 'white'}`,
        },
        '& .MuiSelect-root.Mui-disabled': {
            opacity: '0.6',
        },
        '& .MuiSelect-selectMenu': {
            minHeight: '2rem',
            lineHeight: '2rem',
        },
        '& .MuiSelect-icon': {
            color: `${props.light ? 'black' : 'white'}`,
        },
    }),
})((props) => <MuiTextField {...props} />);

export default TextField;
