import { Typography } from '@material-ui/core';
import UiBreadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import './breadcrumbs.css';

function Breadcrumbs({ breadcrumbs }) {
    return (
        <UiBreadcrumbs
            className='breadcrumbs'
            separator={<NavigateNextIcon fontSize='small' />}
            aria-label='breadcrumb'
        >
            {breadcrumbs.map((breadcrumb, index, arr) => {
                if (index < arr.length - 1) {
                    return (
                        <Link color='inherit' href={breadcrumb.link} key={breadcrumb.label}>
                            {breadcrumb.label}
                        </Link>
                    );
                }
                return (
                    <Typography variant='body1' color='textPrimary' key={breadcrumb.label}>
                        {breadcrumb.label}
                    </Typography>
                );
            })}
        </UiBreadcrumbs>
    );
}

export default Breadcrumbs;
