import i18next from 'i18next';
import globalCAT from "./translations/cat/global.json";
import globalEN from "./translations/en/global.json";
import globalES from "./translations/es/global.json";

i18next.init({
    interpolation: { escapeValue: false },
    lng: "es",
    resources: {
        es: {
            global: globalES,
        },
        en: {
            global: globalEN,
        },
        cat: {
            global: globalCAT,
        },
    }
});

export default i18next;