import { http } from 'infrastructure/http/http';

const login = async (userCredentials) => {
    const body = {
        email: userCredentials.email,
        password: userCredentials.password,
        recaptcha: userCredentials.recaptcha
    }
    return http.post(`auth/login`, body);
    
}

const register = async (userData) => {
    const body = {
        name: userData.name,
        email: userData.email,
        password: userData.password,
        recaptcha: userData.recaptcha,
        role: 'ADMIN'
    }
    return http.post(`auth/register`, body);

}

const activateAccount = async (activationCode) => {
    const headers = {
        Authorization: `Bearer ${activationCode}`
    }
    return http.post(`auth/email-verified`, undefined, headers);

}

const recoverPassword = async (params) => {
    const body =  {
        'email': params.email,
        'recaptcha': params.recaptcha
    }

    return http.post(`auth/forgot`, body);

}

const changePassword = async (params) => {
    const body = {
        'newPassword': params.password,
        'recaptcha': params.recaptcha
    }

    const headers = {
        Authorization: `Bearer ${params.resetCode}`
    }

    return http.post(`auth/new-password`, body, headers);

}

export function useAuth() {
    return {
        login,
        register,
        activateAccount,
        recoverPassword,
        changePassword
    }
}