import { http } from 'infrastructure/http/http'

const sendContactForm = async (contact) => {
    const body = {
        name: contact.name,
        email: contact.email,
        subject: contact.subject,
        message: contact.message
    }
    return http.post(`contact`, body);
}

export function useMailer() {
    return {
        sendContactForm
    }
}