const ResourcesData = {
    itemsLabel: 'Producto',
    titleModule: 'gestión',
    listItemsLabel: 'listado',    
    titleSection: 'gestión moldes Imaterialise',
    titleModels: 'gestión Modelos',
    addItem: 'añadir',
    editItem: 'editar',
    selectItem: 'guardar seleccionado',
    item_to_add: 'modelo Imaterialise',
    subItem01: 'categoria',
    labelMold3D: 'molde 3D',
    actionSelect: 'seleccionar',
    actionSave: 'guardar',
    actionGenerate: 'generar molde',

    modelID: 'Id Imaterialise',
    fileUnits: 'Unidad',
    surfaceCm2: 'Superficie Cm2',
    volumeCm3: 'Volumen Cm3',
    locked: 'En uso',
    createdAt: 'Creado',    
    modelFileName: 'Archivo 3d',
    xDimMm: 'Medida X',
    yDimMm: 'Medida Y',
    zDimMm: 'Medida Z',

    /* fom add item */
    addItem: 'añadir',
    selectData: 'selecciona',
    labelName: 'nombre',
    itemCreated: 'guardado',
    picturesCreated: 'guardadas',
    errorLabel: 'error',
    errorMessage: 'no se completó la operación',
    labelCode: 'código',
    labelDescription: 'descripción',
    labelRootCategory: 'categoría raiz',
    labelCategory: 'categoria',
    labelFinishes: 'acabados',
    labelFinish: 'acabado',
    labelPictures: 'imagenes',
    labelMaterial: 'material',
    labelPicture: 'imàgen',
    labelSizes: 'tallas',
    labelSize: 'talla',
    labelAddFinish: 'Acabado',
    labelId: 'identificador',
    labelMaterial: 'material',
    actionSelectMold: 'selecciona molde',
    actionSelectCategory: 'selecciona categoría',
    actionSave: 'guardar',
    errorCreation: '',
    actionCloseDialog: 'cerrar',


  }

  export default ResourcesData;