import { Typography } from '@material-ui/core';
import useRetrieveMenuCategories from 'application/useCases/retrieveMenuCategories';
import useRetrieveProducts from 'application/useCases/retrieveProducts';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';

import Search from 'components/search/Search';
import Breadcrumbs from 'components/ui/breadcrumbs/Breadcrumbs';

import './shop.css';

function Shop() {
    const [t] = useTranslation('global');

    const orderOptions = [
        {
            value: 'relevancy',
            label: t('shop.orderByRelevancy'),
        },
        {
            value: 'low-high-price',
            label: t('shop.orderByLowHighPrice'),
        },
        {
            value: 'high-low-price',
            label: t('shop.orderByHighLowPrice'),
        },
    ];

    const { retrieveProducts } = useRetrieveProducts();
    const [results, setResults] = useState({ items: [], totalPages: 1, totalItems: 0 });
    const [categories, setCategories] = useState([]);
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [title, setTitle] = useState('');
    const [updatingCategories, setUpdatingCategories] = useState(true);

    const { categoryCode, subCategoryCode } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const textSearch = searchParams.get('text');
    const page = searchParams.get('page');
    const order = searchParams.get('order');
    const [filters, setFilters] = useState({
        order: order || orderOptions[0].value,
        page: Number(page) || 1,
    });

    const { retrieveMenuCategories } = useRetrieveMenuCategories();

    const findItem = (arr, key, value) => {
        if (!value) return null;
        return arr.reduce((a, item) => {
            if (a) return a;
            if (item[key] === value) return item;
            if (item.subItems) return findItem(item.subItems, key, value);
        }, null);
    };

    const buildBreadCrumbs = (criteria) => {
        const { category, subCategory } = criteria;
        const newBreadcrumbs = [{ label: t('shop.mainBreadcrumb'), link: t('navLinks.shop') }];
        if (category) {
            newBreadcrumbs.push({
                label: category.label,
                link: `${t('navLinks.shop')}${category.link}`,
            });
        }
        if (subCategory) {
            newBreadcrumbs.push({
                label: subCategory.label,
                link: `${t('navLinks.shop')}${subCategory.link}`,
            });
        }
        return newBreadcrumbs;
    };

    useEffect(() => {
        setUpdatingCategories(true);
        retrieveMenuCategories().then((categories) => {
            setCategories(categories);
            let categorySelected = null;
            let subCategorySelected = null;
            if (categoryCode) {
                categorySelected = findItem(categories, 'id', categoryCode);
            } else if (subCategoryCode) {
                subCategorySelected = findItem(categories, 'id', subCategoryCode);
                categorySelected = findItem(categories, 'id', subCategorySelected?.parentItem);
            }

            setFilters((prevFilters) => {
                let newFilters = {
                    ...prevFilters,
                    category: categorySelected,
                    subCategory: subCategorySelected,
                };
                return newFilters;
            }, setUpdatingCategories(false));
        });
    }, [categoryCode, subCategoryCode]);

    useEffect(() => {
        onChangeFilter('text', textSearch);
    }, [textSearch]);

    useEffect(() => {
        if (updatingCategories) return;
        setBreadcrumbs(buildBreadCrumbs(filters));

        const t = buildTitle(filters);
        setTitle(t);

        retrieveProducts(filters).then((productSearchResults) => {
            setResults(productSearchResults);
        });
    }, [filters]);
    const capitalize = (str) =>
        str && typeof str === 'string' ? str.charAt(0).toUpperCase() + str.slice(1) : str;

    const buildTitle = (filters) => {
        if (filters.text) return t('shop.textSearchResults', { searchText: filters.text });
        if (filters.subCategory) return capitalize(filters.subCategory?.label);
        if (filters.category) return capitalize(filters.category?.label);
        return t('shop.allCategories');
    };

    const onChangeFilter = (name, value) => {
        if (name === 'page' || name === 'order') {
            setSearchParams((searchParams) => {
                searchParams.set(name, value);
                return searchParams;
            });
        }
        setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
    };

    return (
        <section id='shop' className='standard-container'>
            {!!breadcrumbs.length && <Breadcrumbs breadcrumbs={breadcrumbs} />}
            <Typography variant='h6' component='h2' className='shop__title'>
                {title}
            </Typography>
            <Search
                filters={filters}
                onChangeFilter={onChangeFilter}
                results={results}
                categories={categories}
                orderOptions={orderOptions}
            />
        </section>
    );
}

export default Shop;
