import { IconButton, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import OrderRadioButtonsGroup from 'components/ui/radio/RadioGroup';

import './filterModal.css';

// eslint-disable-next-line react/jsx-props-no-spreading
const Transition = forwardRef((props, ref) => <Slide direction='up' ref={ref} {...props} />);
function Modal({ orderOptions, open, onClose, filters, onChangeFilter }) {
    const [t] = useTranslation('global');

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            className='cartModal'
            onClose={onClose}
            aria-labelledby='user-modal'
            aria-describedby='user-modal'
            fullScreen
        >
            <DialogContent>
                <div className='filterModal__header'>
                    <Typography variant='h3' component='h2'>
                        {t('shop.filterTitle')}
                    </Typography>
                    <IconButton aria-label='close' onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <OrderRadioButtonsGroup
                    className='filterModal__order'
                    label={'Ordenado por'}
                    options={orderOptions}
                    value={filters.order}
                    onChange={(value) => onChangeFilter('order', value)}
                />
            </DialogContent>
        </Dialog>
    );
}

export default Modal;
