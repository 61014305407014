import { useTranslation } from 'react-i18next';

import UserModal from 'components/ui/userModal/UserModal';

import PasswordRecoveryForm from '../form/Form';

function Modal() {
    const [t] = useTranslation('global');

    return (
        <UserModal title={t('passwordRecovery.title')}>
            <PasswordRecoveryForm />
        </UserModal>
    );
}

export default Modal;
