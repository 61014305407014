import { useTranslation } from 'react-i18next'
import { Button, Typography } from '@material-ui/core'

import './createYourProduct.css'

function CreateYourProduct() {
  const [t] = useTranslation('global')

  return (
    <section id='createYourProduct'>
      <div className='standard-container  createYourProduct__container'>
        <div className='createYourProduct__imageContainer'>
          <img alt='Dreamdo purple background' src='/images/dreamdo.png' />
        </div>
        <div className='createYourProduct__textContainer'>
          <Typography
            component='p'
            className='createYourProduct__smallText'
            variant='body2'
          >
            {t('createYourProduct.smallText')}
          </Typography>
          <Typography
            component='h2'
            className='createYourProduct__title'
            variant='h2'
          >
            {t('createYourProduct.title')}
          </Typography>
          <Typography
            component='p'
            className='createYourProduct__description'
            variant='body1'
          >
            {t('createYourProduct.description')}
          </Typography>
          <Button
            className='createYourProduct__btn'
            variant='contained'
            color='primary'
            href={`${t('navLinks.create')}`}
          >
            {t('createYourProduct.createBtn')}
          </Button>
        </div>
      </div>
    </section>
  )
}

export default CreateYourProduct
