const Sections = {    
        home: 'inicio',
        custom_service: 'personalitza',
        contact: 'contacte',
        services: 'serveis',
        categories: 'categorias',
        category: 'categoria',
        shop: 'tienda',
        product: 'producto',
        order: 'comanda',
        about_me: 'qui som ?',
        signup: 'registre',
        passwordRecovery: 'recuperar-password',
        signinClient: 'iniciar sesión',
        signinDashboard: 'Gestión',
        term_con: 'termes i condicions',
        userProfile: 'Cuenta de Usuario',
        signout: 'tanca sessió',
        dashboard: 'panel de control',
        privacy: 'Política de privacidad',
        thanksOrder: 'gracias por tu compra',
        errorPayment: 'pago no realizado',
        thanks_order: 'Gracies per la seva compra !!'    
}

export default Sections