import MuiPopover from '@material-ui/core/Popover';
import useCartStorage from 'infrastructure/services/cartStorageAdapter';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Bag from 'components/cart/bag/Bag';
import CartItem from 'components/cart/item/Item';

import CartButton from '../button/Button';

function Popover() {
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);

    const { getCartItems, getCartTotal } = useCartStorage();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const items = getCartItems();
    return (
        <>
            <CartButton handleClick={handleClick} badgeContent={items?.length | 0} />
            <MuiPopover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Bag total={getCartTotal()}>
                    {items.map((item) => (
                        <CartItem item={item} key={item.id} />
                    ))}
                </Bag>
            </MuiPopover>
        </>
    );
}

export default Popover;
