import PopularCategories from 'pages/home/sections/categories/PopularCategories';
import HomeContact from 'pages/home/sections/contact/HomeContact';
import HowItWorks from 'pages/home/sections/howItWorks/HowItWorks';
import HomeLanding from 'pages/home/sections/landing/HomeLanding';
import PopularProducts from 'pages/home/sections/popular/PopularProducts';

import CreateYourProduct from './sections/createYourProduct/CreateYourProduct';

function Home() {
    return (
        <>
            <HomeLanding />
            <PopularProducts />
            <PopularCategories />
            <HowItWorks />
            <CreateYourProduct />
            <HomeContact />
        </>
    );
}

export default Home;
