import CategoryCard from 'components/category/card/Card'

import './list.css'

function List({ categories, handleClickCard }) {
  return (
    <div className='categoryList'>
      {categories.map((category) => (
          <CategoryCard
            key={category.id}
            category={category}
            handleClickCard={handleClickCard}
          />
        ))}
    </div>
  )
}

export default List
