import {
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useState } from 'react';

function PasswordField({
    id,
    label,
    value,
    onChange,
    onBlur,
    helperText,
    error,
    disabled,
    classes,
    required,
}) {
    const [passwordShown, setPasswordShown] = useState(false);

    const togglePasswordVisiblity = () => setPasswordShown((oldValue) => !oldValue);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <FormControl error={error} classes={classes} variant='outlined' fullWidth margin='dense'>
            <InputLabel htmlFor={id} required={required}>
                {label}
            </InputLabel>

            <OutlinedInput
                id={id}
                label={label}
                type={passwordShown ? 'text' : 'password'}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                disabled={disabled}
                endAdornment={
                    <InputAdornment position='end'>
                        <IconButton
                            aria-label='toggle password visibility'
                            onClick={togglePasswordVisiblity}
                            onMouseDown={handleMouseDownPassword}
                        >
                            {passwordShown ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
            />
            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    );
}

export default PasswordField;
