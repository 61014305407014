import { Button, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import QuantityField from 'components/ui/quantityField/QuantityField';
import Select from 'components/ui/select/Select';

import './selector.css';

function Selector({ onSelect, product, onChangeSelection }) {
    const defaultVariant = product.getDefaultVariant();

    const [filters, setFilters] = useState({
        finish: defaultVariant.finish.id,
        size: defaultVariant.size.id,
        material: defaultVariant.material.id,
    });

    const [quantity, setQuantity] = useState(1);

    const [t] = useTranslation('global');

    const calculateTotalPrice = (unitPrice, qty) => {
        const totalPrice = unitPrice * qty;
        return totalPrice && totalPrice.toFixed(2);
    };

    const setFilter = (filterName, filterValue) => {
        setFilters((prevFilters) => ({ ...prevFilters, [filterName]: filterValue }));
    };

    useEffect(() => {
        const finish = product.getFinishes({ material: filters.material })[0].id;
        const size = product.getSizes({ finish, material: filters.material })[0].id;
        setFilters((prevFilters) => {
            const newFilters = { ...prevFilters, finish, size };
            onChangeSelection(product.getVariant(newFilters));
            return newFilters;
        });
    }, [filters.material]);

    const mapOptions = (options) => options.map(({ id, label }) => ({ value: id, label }));
    return (
        <div>
            <div className='productDetail__selectors'>
                <Select
                    id='material'
                    name='material'
                    label={t('productDetail.materialInput')}
                    options={mapOptions(product.getMaterials())}
                    value={filters.material}
                    onChange={(event) => setFilter('material', event.target.value)}
                />
                <Select
                    id='finish'
                    name='finish'
                    label={t('productDetail.finishInput')}
                    options={mapOptions(product.getFinishes(filters))}
                    value={filters.finish}
                    onChange={(event) => setFilter('finish', event.target.value)}
                />
                <Select
                    id='size'
                    name='size'
                    label={t('productDetail.sizeInput')}
                    options={mapOptions(product.getSizes(filters))}
                    value={filters.size}
                    onChange={(event) => setFilter('size', event.target.value)}
                />
                <div className='productDetail__priceAndQuantityContainer'>
                    <QuantityField
                        id='quantity'
                        light
                        label={t('productDetail.quantityInput')}
                        value={quantity}
                        onChange={setQuantity}
                        margin='dense'
                        variant='outlined'
                        minValue={1}
                        maxValue={10}
                    />
                    <div className='productDetail__priceContainer'>
                        <Typography component='h6' className='productDetail__price' variant='h2'>
                            {calculateTotalPrice(product.getUnitPrice(filters), quantity)}€
                        </Typography>
                        <Typography component='p' className='productDetail__tax' variant='body2'>
                            {t('productDetail.taxIncludedText')}
                        </Typography>
                    </div>
                </div>
            </div>
            <div className='productDetail__buttons'>
                {/*     <Favorite /> */}
                <Button
                    className='productDetail__addBtn'
                    variant='contained'
                    color='primary'
                    type='button'
                    onClick={() => onSelect(product.getVariant(filters), quantity)}
                >
                    {t('productDetail.addToCardBtn')}
                </Button>
            </div>
        </div>
    );
}

export default Selector;
