/* eslint-disable no-underscore-dangle */
import { useProduct } from "infrastructure/services/ProductsService";

export default function useRetrieveProduct() {
    const productService = useProduct();

    const mapImage = (image) => ({
        description: image.seoName,
        url: image.fileUrl
    })
    const mapVariant = (variant) => ({
        images: variant.images.map(image => mapImage(image)),
        id: variant._id,
        finish: variant.finish,
        material: variant.material,
        size: variant.size,
        price: variant.price
    });
    const mapItem = (product) => ({
        id: product._id,
        description: product.description,
        title: product.name,
        slug: product.slug,
        tags: product.tags,
        category: {
            slug: product.category.slug,
            name: product.category.name,
            code: product.category._id
        },
        subcategory: {
            slug: product.subcategory.slug,
            name: product.subcategory.name,
            code: product.subcategory._id
        },
        variants: product.variants.map(variant => mapVariant(variant)),

        applyFilters(filters) {
            if (!filters) return this.variants;
            return this.variants.filter((variant) => {
                let condition = true;
                Object.entries(filters).forEach(([filterKey, filterValue]) => {
                    condition = condition && variant[filterKey]
                        .id === filterValue;
                })
                return condition;
            })
        },
        getVariant(filters) {
            return this.applyFilters(filters)[0];
        },
        getMaterials() {
            return this.variants.map((value) => value.material)
                // get unique values
                .filter((value, index, array) => array.findIndex((item) => item.id === value.id) === index)
        },
        getFinishes(filters) {
            return this.applyFilters({ material: filters.material }).map((value) => value.finish)
                // get unique values
                .filter((value, index, array) => array.findIndex((item) => item.id === value.id) === index)
        },
        getSizes(filters) {
            return this.applyFilters({ material: filters.material, finish: filters.finish }).map((value) => value.size)
                // get unique values
                .filter((value, index, array) => array.findIndex((item) => item.id === value.id) === index)
        },
        getDefaultVariant() { return this.variants[0] },

        getUnitPrice(filters) {
            const value = this.variants.find(
                (variant) => variant.finish.id === filters.finish
                    && variant.size.id === filters.size
                    && variant.material.id === filters.material,
            );
            return value?.price || 0;
        }
    })

    async function retrieveProduct(id) {
        try {

            const product = await productService.getProductDetail(id);
            return mapItem(product);

        } catch (e) {
            return {}; // TODO throw error and show not found page?
        }

    }


    return { retrieveProduct };
}