import { ACTION_TYPES, STORE_KEY } from "infrastructure/reducers/cart/constants";
import { useEffect } from "react";
import { useStore } from "./store";

function useCartStorage() {
    const [state, dispatch] = useStore();

    const addToCart = (cartItem) => {
        dispatch(
            {
                type: ACTION_TYPES.ADD_TO_CART,
                payload: cartItem
            });

    }

    const getCartItems = () => {

        const a = state.cart.cartItems.map(cartItem => {

            const cartItemVariant = state.cart.cartVariants[cartItem.variant];
            const cartItemProduct = state.cart.cartProducts[cartItem.product];
            return {
                id: cartItemVariant.id,
                price: cartItemVariant.price || 0,
                quantity: cartItem.quantity,
                productId: cartItemProduct.id,
                title: cartItemProduct.title,
                image: cartItemVariant.images[0],
                url: cartItemProduct.link,
                description: `Material ${cartItemVariant.material.label} / Acabado ${cartItemVariant.finish.label} / Tamaño ${cartItemVariant.size.label}`

            }
        })
        return a;
    }

    const getCartTotal = () => getCartItems().reduce((acc, currentValue) => acc + currentValue.quantity * currentValue.price, 0)

    useEffect(() => {
        localStorage.setItem(STORE_KEY, JSON.stringify(state.cart));
    }, [state.cart])

    return {
        addToCart,
        cart: state.cart,
        getCartItems,
        getCartTotal
    };
}

export default useCartStorage;
