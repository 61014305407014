/* eslint-disable react/jsx-filename-extension */
import { MuiThemeProvider } from '@material-ui/core';
import i18n from 'i18n';
import { NotificationsProvider } from 'infrastructure/services/notifier';
import { StoreProvider } from 'infrastructure/services/store';
import ReactDOM from 'react-dom';
import { I18nextProvider } from "react-i18next";
import { BrowserRouter as Router } from 'react-router-dom';
import theme from 'theme';
import App from './App';
import AppState from './domain/context/AppState';
import './index.css';
import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
            <StoreProvider>
                <NotificationsProvider>
                    <AppState>
                        <Router>
                            <App />
                        </Router>
                    </AppState>
                </NotificationsProvider>
            </StoreProvider>
        </I18nextProvider>
    </MuiThemeProvider>,
    document.getElementById('root')
);
registerServiceWorker();
