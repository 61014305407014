import { createTheme } from '@material-ui/core';

const theme = createTheme({
    palette: {
        primary: {
            light: '#757ce8',
            main: '#5C429A',
            dark: '#3f2188',
            contrastText: '#fff',
        },
        secondary: {
            main: '#7F7B89'
        },
    },

    overrides: {
        MuiTypography: {
            h1: {
                fontSize: '2.25rem',
                fontFamily: 'Muli',
                fontStyle: 'normal',
                fontWeight: 900,
                lineHeight: '130%',
                '@media (min-width:1024px)': {
                    fontSize: '3rem'
                }
            },
            h2: {
                fontSize: '2rem',
                fontFamily: 'Muli',
                fontStyle: 'normal',
                fontWeight: 900,
                lineHeight: '130%',
                '@media (min-width:1024px)': {
                    fontSize: '2.5rem'
                }
            },
            h3: {
                fontSize: '1.5rem',
                fontFamily: 'Muli',
                fontStyle: 'normal',
                fontWeight: 900,
                lineHeight: '130%',
                '@media (min-width:1024px)': {
                    fontSize: '2rem'
                }
            },
            h6: {
                fontSize: '1.25rem',
                fontFamily: 'Muli',
                fontStyle: 'normal',
                fontWeight: 900,
                lineHeight: '25px',
                '@media (min-width:1024px)': {
                    fontSize: '1.5rem'
                }
            },
            body1: {
                fontSize: '1.125rem',
                fontFamily: 'Noto Sans',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '150%',
                '@media (min-width:1024px)': {
                    fontSize: '1.25rem'
                }
            },
            body2: {
                fontSize: '0.875rem',
                fontFamily: 'Noto Sans',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '150%',
                '@media (min-width:1024px)': {
                    fontSize: '1rem'
                }
            }
        },
        MuiButton: {
            containedPrimary: {
                fontWeight: 'bold',
                fontFamily: 'Muli',
                fontSize: '1em',
                borderRadius: '2em',
                padding: '0.875rem 4rem',
                backgroundColor: '#3f2188',
                border: '0em',
                color: '#fff',
                textTransform: 'none'
            }
        },
        MuiInput: {
            outlined: {
                fontFamily: 'Noto Sans'
            }
        },
        MuiFormLabel: {
            root: {
                fontFamily: 'Noto Sans'
            }
        },
        MuiFormControl: {
            marginDense: {
                marginTop: '16px',
                marginBottom: '8px'
            }
        }
    }
});

/* 
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
 */



export default theme;