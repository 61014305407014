/* eslint-disable react/jsx-props-no-spreading */
import { withStyles } from '@material-ui/core/styles';

import PasswordField from './PasswordField';

const StyledPasswordField = withStyles({
    root: (props) => ({
        height: 'auto',
        color: `${props.light ? 'black' : 'white'}`,
        background: 'transparent',
        '& input': {
            height: '2rem',
        },
        '& input.Mui-disabled': {
            opacity: '0.6',
        },
        '& label.Mui-focused': {
            color: `${props.light ? '#3f2188' : 'white'}`,
        },
        '& label.Mui-disabled': {
            color: `${props.light ? 'black' : '#ffffffe6'}`,
        },
        '& label:not(.MuiInputLabel-shrink)': {
            transform: 'translate(14px, 19px) scale(1)',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: `${props.light ? '' : 'white'}`,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: `${props.light ? '' : 'white'}`,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: `${props.light ? '' : 'white'}`,
            },
            '&.Mui-focused fieldset': {
                borderColor: `${props.light ? '' : 'white'}`,
            },
            '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                borderColor: `${props.light ? '' : 'white'}`,
            },
        },
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: '0',
        },
    }),
})((props) => <PasswordField {...props} classes={{ root: props.classes.root }} />);

export default StyledPasswordField;
