const Models3dData = {
    itemsLabel: 'modelos 3d',
    titleModule: 'gestión',
    listItemsLabel: 'listado',
    
    addItem: 'añadir',
    actionUpload: 'subir',
    actionSave: 'guardar',
    //fields model

    

  }
  export default Models3dData;