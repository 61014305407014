import { useTranslation } from 'react-i18next'
import { Button, Typography } from '@material-ui/core'

import useViewport from 'hooks/useViewport'

import './homeLanding.css'

function HomeLanding() {
  const [t, i18n] = useTranslation('global')
  const { isDesktop } = useViewport()

  const options = [
    { value: '-', text: 'Select lenguage' },
    { value: 'en', text: 'EN' },
    { value: 'es', text: 'ES' },
    { value: 'cat', text: 'CAT' }
  ]

  const handleChange = event => {
    i18n.changeLanguage(event.target.value)
  }

  const backgroundImageStyle = {
    backgroundImage: `url('${process.env.PUBLIC_URL}/images/cover-desktop.png')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPositionX: '79%'
  }

  const buildStyleForDesktop = (showBgInDesktop) => ((isDesktop() && showBgInDesktop) || (!isDesktop() && !showBgInDesktop)) ? backgroundImageStyle : {};

  return (
      <section id='homeLanding' style={buildStyleForDesktop(false)}>
          <div
              className='homeLanding__container standard-container'
              style={buildStyleForDesktop(true)}
          >
              <select value={options[0].value} onChange={handleChange}>
                  {options.map((option) => (
                      <option key={`option-${option.value}`} value={option.value}>
                          {option.text}
                      </option>
                  ))}
              </select>
              <div className='homeLanding__content'>
                  <Typography className='homeLanding__title' component='h1' variant='h1'>
                      {t('homeLanding.title')}
                  </Typography>
                  <Typography className='homeLanding__description' component='p' variant='body1'>
                      {t('homeLanding.introText')}
                  </Typography>
                  <Button
                      className='homeLanding__btn'
                      variant='contained'
                      color='primary'
                      href={`${t('navLinks.shop')}`}
                  >
                      {t('homeLanding.btnShop')}
                  </Button>
              </div>
          </div>
      </section>
  );
}

export default HomeLanding
