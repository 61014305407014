import { http } from 'infrastructure/http/http';

const getCategories = async (params) => {
    const body = new URLSearchParams();

    body.append('active', 1);
    if (params.limit) {
        body.append('limit', params.limit);
    }
    body.append('menu', Number(params.menu));
    body.append('root', Number(params.root));
    body.append('include_childs', Number(params.includeChilds));
    return http.get('categories', body);
}

export function useCategory() {
    return {
        getCategories,
    }
}