const NavLinks = {    
    home: '/',
    custom_service: '/personaliza',
    contact: '/contacto',
    services:'/servicios',
    category:'/categoria',
    categories: '/categorias',
    shop: '/tienda',
    article: 'detalle-articulo',
    product: 'producto',
    about_me: '/quienes-somos',
    signup: '/registro',
    checkout: '/comprar',
    validation_account: '/validacion-registro',
    signinClient: '/iniciar-sesion',
    signout: '/cerrar-sesion',
    recoveryPassword: '/recuperar-password',
    term_con: '/terminos-condiciones',
    thanks_order: '/gracias-por-tu-compra',
    order_cancelled: '/pago-cancelado',
    privacy: 'politica-privacidad',
    order: '/pedido',
    user_profile: '/cuenta-usuario',
    wish_list: '/lista-deseos',
    cookies: '/politica-cookies',
    dashboard: '/panel-control'    
}

export default NavLinks