import { Link, useLocation } from 'react-router-dom';

function ModalLink({ to, children }) {
    const location = useLocation();

    return (
        <Link to={to} state={{ previousLocation: location.state?.previousLocation }}>
            {children}
        </Link>
    );
}

export default ModalLink;
