import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

function NotAvailable() {
    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
        },
        title: {
            color: '#3f2188',
            fontSize: '5rem',
        },
    }));

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Typography variant='h2' component='h2' className={classes.title}>
                Coming soon!
            </Typography>
        </div>
    );
}

export default NotAvailable;
