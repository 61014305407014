import { useTranslation } from 'react-i18next';

import ModalLink from 'components/ui/modalLink/ModalLink';
import UserModal from 'components/ui/userModal/UserModal';

import LoginForm from '../form/Form';

function Modal() {
    const [t] = useTranslation('global');

    return (
        <UserModal title={t('login.title')}>
            <LoginForm />
            <div className='userModal__callToActionLink'>
                {t('userModal.withoutAnAccountText')}
                <ModalLink to={t('navLinks.signUp')}>{t('userModal.registerLink')}</ModalLink>
            </div>
        </UserModal>
    );
}

export default Modal;
