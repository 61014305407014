import { IconButton, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import { forwardRef } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useLocation, useNavigate } from 'react-router-dom';

import './userModal.css';

// eslint-disable-next-line react/jsx-props-no-spreading
const Transition = forwardRef((props, ref) => <Slide direction='up' ref={ref} {...props} />);
function UserModal({ title, children }) {
    const navigate = useNavigate();
    const location = useLocation();

    const handleClose = () => {
        navigate(location.state?.previousLocation || '/');
    };

    return (
        <Dialog
            open
            TransitionComponent={Transition}
            keepMounted
            className='user-modal'
            onClose={handleClose}
            aria-labelledby='user-modal'
            aria-describedby='user-modal'
        >
            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_CAPTCHA}>
                <DialogContent>
                    <IconButton
                        aria-label='close'
                        onClick={handleClose}
                        className='user-modal__close'
                    >
                        <CloseIcon />
                    </IconButton>

                    <Typography variant='h2' component='h2' className='user-modal__title'>
                        {title}
                    </Typography>

                    {children}
                </DialogContent>
            </GoogleReCaptchaProvider>
        </Dialog>
    );
}

export default UserModal;
